// This file is automatically @generated by scripts/generate-json-schema/index.ts

export const topLevelSchemas = {
  complianceQueueApproveDenyFormStates: {
    type: 'object',
    additionalProperties: false,
    patternProperties: {
      '^[0-9]+$': {
        type: 'object',
        additionalProperties: { type: ['null', 'string', 'boolean'] },
      },
    },
  },
  deloitteDemo: { type: 'boolean' },
  developersPreference: {
    anyOf: [
      { $ref: 'defs.json#/definitions/DevelopersPreference' },
      { type: 'null' },
    ],
  },
  postProjectObject: {
    anyOf: [
      { $ref: 'defs.json#/definitions/ProjectViewProject' },
      { type: 'null' },
    ],
  },
  projectOverlayThreadComplete: {
    type: 'object',
    additionalProperties: false,
    patternProperties: { '^[0-9]+$': { type: 'boolean' } },
  },
  projectOverlayFullFlowComplete: { type: 'boolean' },
  webappChats: {
    type: 'array',
    items: { $ref: 'defs.json#/definitions/SavedChatAttributes' },
  },
  webappChatDraftMessages: {
    type: 'object',
    additionalProperties: {
      anyOf: [
        { $ref: 'defs.json#/definitions/DraftMessage' },
        { type: 'null' },
      ],
    },
  },
  webappThreadListMinimise: { type: 'boolean' },
  taskList: {
    anyOf: [{ $ref: 'defs.json#/definitions/TaskListState' }, { type: 'null' }],
  },
  taskListCurrentTaskClean: { type: ['null', 'boolean'] },
  lastSeenPjpAssistant: { $ref: 'defs.json#/definitions/LastSeenPjpAssistant' },
  hireMeDraft: {
    anyOf: [{ $ref: 'defs.json#/definitions/HireMeDraft' }, { type: 'null' }],
  },
  navSavedAlertsLastReadTime: { type: ['null', 'number'] },
  navUpdatesLastReadTime: { type: ['null', 'number'] },
  navNotificationUpdatesLastReadTime: { type: ['null', 'number'] },
  dashboardMyProjectsUserType: {
    anyOf: [
      { enum: ['employer', 'freelancer'], type: 'string' },
      { type: 'null' },
    ],
  },
  enterpriseContactFormSubmitted: { type: 'boolean' },
  postJobPageDraft: {
    anyOf: [
      {
        description:
          "This stores the value of the fields in PJP which can be saved as a draft.\nIt consist of fields for both Projects and Contests. In the DB, this is\nstored as a stringified JSON in the `draft` table.\n\nNote that all fields are nullable except for the id, title, and description.\nAn id is required for the documents that we keep in the datastore. Also, we\nonly begin saving drafts when there's a title and description.\n\nAdditionally, the reason for choosing `null` over `undefined` is that the\nfield whose value is `undefined` gets removed from the resulting string when\nwe `JSON.stringify` the object. We want to avoid having inconsistent format\nin the DB so we used `null` instead.\n\nThere is also an inconsistent type for contestUpgrades where it initially\ncontains `ContestUpgrade`. When the contest type card is selected, it now\ncontains an array of selected upgrades (T92144).\n\nLastly, the corresponding type of the following fields are currently defined\nin `JobPostModule`. In order to declare the types properly, we must move\nthe definitions to a model file so that they can be imported here (T92109):\n- contestDurationType: DurationType\n- jobType: JobType\n- projectBudgetType: BudgetType",
        allOf: [
          { $ref: 'defs.json#/definitions/BasePostJobPageFormState' },
          {
            type: 'object',
            properties: {
              communitySelection: {
                enum: ['facebook', 'freelancer', 'heygen'],
                type: 'string',
              },
              contestDuration: { type: ['null', 'number'] },
              contestDurationType: { type: ['null', 'string'] },
              contestPrize: { type: ['null', 'number'] },
              contestType: {
                anyOf: [
                  { enum: ['basic', 'guaranteed'], type: 'string' },
                  { type: 'null' },
                ],
              },
              currencyId: { type: ['null', 'number'] },
              hasBillingCode: { type: ['null', 'boolean'] },
              billingCode: { type: ['null', 'string'] },
              audience: {
                anyOf: [
                  {
                    enum: ['flnltd', 'flnltd_and_marketplace'],
                    type: 'string',
                  },
                  { type: 'null' },
                ],
              },
              isLocal: { type: ['null', 'boolean'] },
              jobType: { type: ['null', 'string'] },
              projectBudgetRange: {
                anyOf: [
                  { $ref: 'defs.json#/definitions/ProjectBudgetOption' },
                  { type: 'null' },
                ],
              },
              projectBudgetType: { type: ['null', 'string'] },
              projectType: {
                anyOf: [
                  { enum: ['recruiter', 'standard'], type: 'string' },
                  { type: 'null' },
                ],
              },
              templateSelection: {
                enum: [
                  'advanced-matching',
                  'facebook-pixel',
                  'facebook-pixel-and-catalog',
                  'other',
                ],
                type: 'string',
              },
            },
            required: [
              'contestDuration',
              'contestDurationType',
              'contestPrize',
              'contestType',
              'currencyId',
              'isLocal',
              'jobType',
              'projectBudgetRange',
              'projectBudgetType',
              'projectType',
            ],
          },
        ],
      },
      {
        allOf: [
          { $ref: 'defs.json#/definitions/BasePostJobPageFormState' },
          {
            type: 'object',
            properties: {
              isOnBehalf: { type: 'boolean' },
              ownershipUser: { type: ['null', 'string'] },
              startDate: { type: 'string', format: 'date-time' },
              completionDate: {
                anyOf: [
                  { type: 'string', format: 'date-time' },
                  { type: 'null' },
                ],
              },
              isCompletionDateOngoing: { type: 'boolean' },
              wbsCode: { type: ['null', 'string'] },
              noWbsCode: { type: ['null', 'boolean'] },
              billableHours: { type: ['null', 'number'] },
              deloitteProjectType: {
                anyOf: [
                  {
                    enum: [
                      '',
                      'client_billable',
                      'firm_initiative',
                      'internal_services',
                      'marketplace_or_firm_contribution',
                      'other_investment',
                      'prd',
                      'skills_based_volunteering',
                    ],
                    type: 'string',
                  },
                  { type: 'null' },
                ],
              },
              contribution: {
                anyOf: [
                  {
                    enum: [
                      '',
                      'business_development',
                      'dei_and_wellbeing',
                      'ld_design_and_delivery',
                      'market_support',
                      'network_or_local_office',
                      'recruiting_and_mentorship',
                      'skills_based_volunteering',
                    ],
                    type: 'string',
                  },
                  { type: 'null' },
                ],
              },
              businessLine: {
                anyOf: [
                  {
                    enum: [
                      '',
                      'audit_and_assurance',
                      'consulting',
                      'gps_enabling_area',
                      'internal_services',
                      'other',
                      'risk_and_financial_advisory',
                      'tax',
                    ],
                    type: 'string',
                  },
                  { type: 'null' },
                ],
              },
              practiceGroup: {
                anyOf: [
                  {
                    enum: [
                      '',
                      'commercial',
                      'government_and_public_services',
                      'other',
                    ],
                    type: 'string',
                  },
                  { type: 'null' },
                ],
              },
              industrySector: {
                anyOf: [
                  {
                    enum: [
                      '',
                      'automotive',
                      'automotive_transportation_hospitality_and_services',
                      'banking_and_capital_markets',
                      'civil_government',
                      'consumer_products',
                      'cross_sector_c',
                      'cross_sector_ci',
                      'cross_sector_eri',
                      'cross_sector_fs',
                      'cross_sector_gps',
                      'cross_sector_lshc',
                      'cross_sector_tmt',
                      'defense_security_and_justice',
                      'energy_and_chemicals',
                      'federal_health',
                      'health_care',
                      'holding_companies',
                      'individuals_and_estates',
                      'industrial_products_and_construction',
                      'industry',
                      'insurance',
                      'investment_management',
                      'investment_management_and_real_estate',
                      'life_sciences',
                      'media_and_entertainment',
                      'not_applicable',
                      'not_assigned',
                      'oil_gas_and_chemicals',
                      'power_and_utilities',
                      'power_utilities_and_renewables',
                      'real_estate',
                      'retail_and_consumer_products',
                      'retail_wholesale_and_distribution',
                      'state_local_and_higher_education',
                      'technology',
                      'telecom_media_and_entertainment',
                      'transportation_hospitality_and_services',
                      'unclassified_industries',
                    ],
                    type: 'string',
                  },
                  { type: 'null' },
                ],
              },
              offeringPortfolio: {
                anyOf: [
                  {
                    enum: [
                      '',
                      'client_and_market_growth',
                      'core_business_operations',
                      'customer',
                      'customer_and_marketing',
                      'dc_human_capital',
                      'dc_technology',
                      'enterprise_operations',
                      'gps_cmg',
                      'gps_compliance',
                      'gps_consulting_natl_office',
                      'gps_contracts',
                      'gps_dc_management',
                      'gps_ea_management',
                      'gps_enabling_areas',
                      'gps_finance',
                      'gps_general_counsel',
                      'gps_operations',
                      'gps_talent',
                      'human_capital',
                      'innovation_and_technology',
                      'internal_services',
                      'mergers_and_acquisitions',
                      'national_consulting',
                      'national_consulting_management',
                      'nbi',
                      'not_applicable',
                      'platforms',
                      'strategy_and_analytics',
                    ],
                    type: 'string',
                  },
                  { type: 'null' },
                ],
              },
              itar: { type: 'boolean' },
              limitGigWorkerLevels: {
                $ref: 'defs.json#/definitions/DelioitteGigWorkerLevel',
              },
              audience: { $ref: 'defs.json#/definitions/DeloitteAudienceType' },
              budget: {
                type: 'object',
                properties: {
                  minimum: { type: ['null', 'number'] },
                  maximum: { type: ['null', 'number'] },
                  fundingRequired: { type: ['null', 'boolean'] },
                },
                required: ['maximum', 'minimum'],
              },
              ppmdEmailAddress: { type: ['null', 'string'] },
              externalDescription: { type: ['null', 'string'] },
            },
            required: [
              'audience',
              'billableHours',
              'businessLine',
              'contribution',
              'deloitteProjectType',
              'industrySector',
              'isCompletionDateOngoing',
              'isOnBehalf',
              'itar',
              'limitGigWorkerLevels',
              'offeringPortfolio',
              'practiceGroup',
              'startDate',
              'wbsCode',
            ],
          },
        ],
      },
      { type: 'null' },
    ],
  },
  manageRecentTable: {
    anyOf: [
      { $ref: 'defs.json#/definitions/ManageRecentTable' },
      { type: 'null' },
    ],
  },
  inviteToBidDiscardedFreelancers: {
    type: 'object',
    additionalProperties: false,
    patternProperties: {
      '^[0-9]+$': { type: 'array', items: { type: 'number' } },
    },
  },
  flCardsExpandedStatus: {
    type: 'object',
    additionalProperties: { type: 'boolean' },
  },
  abTestVariations: { $ref: 'defs.json#/definitions/ABTestVariationCache' },
  viewedItems: {
    $ref: 'defs.json#/definitions/Partial<{project:ItemsViewedMap;}>',
  },
  hideMobileNewMessageToast: { type: 'boolean' },
  searchServiceFilters: { $ref: 'defs.json#/definitions/SearchFilters' },
  freelancerOnboardingResumeUploaded: {
    description:
      'Used on the CV upload step of the freelancer onboarding flow\nTODO: T236205 Remove after endpoint allows fetching the uploaded file',
    type: 'boolean',
  },
  freelancerOnboardingParsedResume: {
    anyOf: [
      { $ref: 'defs.json#/definitions/UserParsedResume' },
      { type: 'null' },
    ],
  },
  freelancerOnboardingResumeParsingStatus: {
    anyOf: [
      { enum: ['error', 'in_progress', 'success'], type: 'string' },
      { type: 'null' },
    ],
  },
  deviceId: { type: ['null', 'string'] },
  dontAskAgainLastChecked: { type: 'number' },
  nativeRatingPrompt: {
    type: 'object',
    properties: {
      lastPrompted: {
        type: 'object',
        properties: {
          date: { type: 'number' },
          leftFeedbackOrRating: { type: 'boolean' },
        },
        required: ['date', 'leftFeedbackOrRating'],
      },
      oneOff: {
        type: 'object',
        properties: { acceptProjectOrMilestoneRelease: { type: 'boolean' } },
      },
    },
    required: ['lastPrompted', 'oneOff'],
  },
  lastUsedForms: { $ref: 'defs.json#/definitions/LastUsedForms' },
  testProperty: { type: 'number' },
  testName: { type: ['null', 'string'] },
  testProfile: { $ref: 'defs.json#/definitions/MockProfile' },
  externalQuotation: {
    anyOf: [
      { $ref: 'defs.json#/definitions/ExternalQuotation' },
      { type: 'null' },
    ],
  },
  threeDSChallengeCookie: { type: 'string' },
  notificationsRequested: { type: 'boolean' },
  loadIdPendingAction: { type: ['null', 'number'] },
  groupsHomepageSelectedView: {
    $ref: 'defs.json#/definitions/GroupsViewFilterSelection',
  },
  waitingScreenSteps: { $ref: 'defs.json#/definitions/WaitingScreenSteps' },
  giveGetReferrerUsername: { type: ['null', 'string'] },
  giveGetFooterClosed: { type: 'boolean' },
  aiPromptSelection: { type: ['null', 'number'] },
  aiPromptEnabled: { type: 'boolean' },
  aiConsultantChatHistory: {
    anyOf: [
      {
        type: 'array',
        items: { $ref: 'defs.json#/definitions/AiConsultantMessage' },
      },
      { type: 'null' },
    ],
  },
  aiConsultantGeneratedProjectDraft: {
    anyOf: [
      { $ref: 'defs.json#/definitions/AiConsultantGeneratedProjectDraft' },
      { type: 'null' },
    ],
  },
  aiPlaygroundConversationStorage: {
    anyOf: [
      {
        type: 'array',
        items: {
          $ref: 'defs.json#/definitions/AiPlaygroundConversationSaveFile',
        },
      },
      { type: 'null' },
    ],
  },
  aiPlaygroundSavedTools: {
    anyOf: [
      {
        type: 'array',
        items: { $ref: 'defs.json#/definitions/AiPlaygroundSavedTool' },
      },
      { type: 'null' },
    ],
  },
  theme: {
    anyOf: [
      { enum: ['dark', 'light', 'system'], type: 'string' },
      { type: 'null' },
    ],
  },
  userOriginalTheme: {
    anyOf: [{ enum: ['dark', 'light'], type: 'string' }, { type: 'null' }],
  },
  appInstallPromptDismissals: {
    $ref: 'defs.json#/definitions/AppInstallPromptDismissals',
  },
  appInstallPromptEmailDismissalDays: { type: 'number' },
};

export const definitions = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    LocalStorageTypes: {
      description:
        'Holds the types which can be stored in local storage i.e. are JSON serialisable.\nValues can be restricted to solely their type,\nor can also be allowed to be `null` (if the item needs to be able to be deleted).',
      type: 'object',
      properties: {
        complianceQueueApproveDenyFormStates: {
          type: 'object',
          additionalProperties: false,
          patternProperties: {
            '^[0-9]+$': {
              type: 'object',
              additionalProperties: { type: ['null', 'string', 'boolean'] },
            },
          },
        },
        deloitteDemo: { type: 'boolean' },
        developersPreference: {
          anyOf: [
            { $ref: 'defs.json#/definitions/DevelopersPreference' },
            { type: 'null' },
          ],
        },
        postProjectObject: {
          anyOf: [
            { $ref: 'defs.json#/definitions/ProjectViewProject' },
            { type: 'null' },
          ],
        },
        projectOverlayThreadComplete: {
          type: 'object',
          additionalProperties: false,
          patternProperties: { '^[0-9]+$': { type: 'boolean' } },
        },
        projectOverlayFullFlowComplete: { type: 'boolean' },
        webappChats: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/SavedChatAttributes' },
        },
        webappChatDraftMessages: {
          type: 'object',
          additionalProperties: {
            anyOf: [
              { $ref: 'defs.json#/definitions/DraftMessage' },
              { type: 'null' },
            ],
          },
        },
        webappThreadListMinimise: { type: 'boolean' },
        taskList: {
          anyOf: [
            { $ref: 'defs.json#/definitions/TaskListState' },
            { type: 'null' },
          ],
        },
        taskListCurrentTaskClean: { type: ['null', 'boolean'] },
        lastSeenPjpAssistant: {
          $ref: 'defs.json#/definitions/LastSeenPjpAssistant',
        },
        hireMeDraft: {
          anyOf: [
            { $ref: 'defs.json#/definitions/HireMeDraft' },
            { type: 'null' },
          ],
        },
        navSavedAlertsLastReadTime: { type: ['null', 'number'] },
        navUpdatesLastReadTime: { type: ['null', 'number'] },
        navNotificationUpdatesLastReadTime: { type: ['null', 'number'] },
        dashboardMyProjectsUserType: {
          anyOf: [
            { enum: ['employer', 'freelancer'], type: 'string' },
            { type: 'null' },
          ],
        },
        enterpriseContactFormSubmitted: { type: 'boolean' },
        postJobPageDraft: {
          anyOf: [
            {
              description:
                "This stores the value of the fields in PJP which can be saved as a draft.\nIt consist of fields for both Projects and Contests. In the DB, this is\nstored as a stringified JSON in the `draft` table.\n\nNote that all fields are nullable except for the id, title, and description.\nAn id is required for the documents that we keep in the datastore. Also, we\nonly begin saving drafts when there's a title and description.\n\nAdditionally, the reason for choosing `null` over `undefined` is that the\nfield whose value is `undefined` gets removed from the resulting string when\nwe `JSON.stringify` the object. We want to avoid having inconsistent format\nin the DB so we used `null` instead.\n\nThere is also an inconsistent type for contestUpgrades where it initially\ncontains `ContestUpgrade`. When the contest type card is selected, it now\ncontains an array of selected upgrades (T92144).\n\nLastly, the corresponding type of the following fields are currently defined\nin `JobPostModule`. In order to declare the types properly, we must move\nthe definitions to a model file so that they can be imported here (T92109):\n- contestDurationType: DurationType\n- jobType: JobType\n- projectBudgetType: BudgetType",
              allOf: [
                { $ref: 'defs.json#/definitions/BasePostJobPageFormState' },
                {
                  type: 'object',
                  properties: {
                    communitySelection: {
                      enum: ['facebook', 'freelancer', 'heygen'],
                      type: 'string',
                    },
                    contestDuration: { type: ['null', 'number'] },
                    contestDurationType: { type: ['null', 'string'] },
                    contestPrize: { type: ['null', 'number'] },
                    contestType: {
                      anyOf: [
                        { enum: ['basic', 'guaranteed'], type: 'string' },
                        { type: 'null' },
                      ],
                    },
                    currencyId: { type: ['null', 'number'] },
                    hasBillingCode: { type: ['null', 'boolean'] },
                    billingCode: { type: ['null', 'string'] },
                    audience: {
                      anyOf: [
                        {
                          enum: ['flnltd', 'flnltd_and_marketplace'],
                          type: 'string',
                        },
                        { type: 'null' },
                      ],
                    },
                    isLocal: { type: ['null', 'boolean'] },
                    jobType: { type: ['null', 'string'] },
                    projectBudgetRange: {
                      anyOf: [
                        { $ref: 'defs.json#/definitions/ProjectBudgetOption' },
                        { type: 'null' },
                      ],
                    },
                    projectBudgetType: { type: ['null', 'string'] },
                    projectType: {
                      anyOf: [
                        { enum: ['recruiter', 'standard'], type: 'string' },
                        { type: 'null' },
                      ],
                    },
                    templateSelection: {
                      enum: [
                        'advanced-matching',
                        'facebook-pixel',
                        'facebook-pixel-and-catalog',
                        'other',
                      ],
                      type: 'string',
                    },
                  },
                  required: [
                    'contestDuration',
                    'contestDurationType',
                    'contestPrize',
                    'contestType',
                    'currencyId',
                    'isLocal',
                    'jobType',
                    'projectBudgetRange',
                    'projectBudgetType',
                    'projectType',
                  ],
                },
              ],
            },
            {
              allOf: [
                { $ref: 'defs.json#/definitions/BasePostJobPageFormState' },
                {
                  type: 'object',
                  properties: {
                    isOnBehalf: { type: 'boolean' },
                    ownershipUser: { type: ['null', 'string'] },
                    startDate: { type: 'string', format: 'date-time' },
                    completionDate: {
                      anyOf: [
                        { type: 'string', format: 'date-time' },
                        { type: 'null' },
                      ],
                    },
                    isCompletionDateOngoing: { type: 'boolean' },
                    wbsCode: { type: ['null', 'string'] },
                    noWbsCode: { type: ['null', 'boolean'] },
                    billableHours: { type: ['null', 'number'] },
                    deloitteProjectType: {
                      anyOf: [
                        {
                          enum: [
                            '',
                            'client_billable',
                            'firm_initiative',
                            'internal_services',
                            'marketplace_or_firm_contribution',
                            'other_investment',
                            'prd',
                            'skills_based_volunteering',
                          ],
                          type: 'string',
                        },
                        { type: 'null' },
                      ],
                    },
                    contribution: {
                      anyOf: [
                        {
                          enum: [
                            '',
                            'business_development',
                            'dei_and_wellbeing',
                            'ld_design_and_delivery',
                            'market_support',
                            'network_or_local_office',
                            'recruiting_and_mentorship',
                            'skills_based_volunteering',
                          ],
                          type: 'string',
                        },
                        { type: 'null' },
                      ],
                    },
                    businessLine: {
                      anyOf: [
                        {
                          enum: [
                            '',
                            'audit_and_assurance',
                            'consulting',
                            'gps_enabling_area',
                            'internal_services',
                            'other',
                            'risk_and_financial_advisory',
                            'tax',
                          ],
                          type: 'string',
                        },
                        { type: 'null' },
                      ],
                    },
                    practiceGroup: {
                      anyOf: [
                        {
                          enum: [
                            '',
                            'commercial',
                            'government_and_public_services',
                            'other',
                          ],
                          type: 'string',
                        },
                        { type: 'null' },
                      ],
                    },
                    industrySector: {
                      anyOf: [
                        {
                          enum: [
                            '',
                            'automotive',
                            'automotive_transportation_hospitality_and_services',
                            'banking_and_capital_markets',
                            'civil_government',
                            'consumer_products',
                            'cross_sector_c',
                            'cross_sector_ci',
                            'cross_sector_eri',
                            'cross_sector_fs',
                            'cross_sector_gps',
                            'cross_sector_lshc',
                            'cross_sector_tmt',
                            'defense_security_and_justice',
                            'energy_and_chemicals',
                            'federal_health',
                            'health_care',
                            'holding_companies',
                            'individuals_and_estates',
                            'industrial_products_and_construction',
                            'industry',
                            'insurance',
                            'investment_management',
                            'investment_management_and_real_estate',
                            'life_sciences',
                            'media_and_entertainment',
                            'not_applicable',
                            'not_assigned',
                            'oil_gas_and_chemicals',
                            'power_and_utilities',
                            'power_utilities_and_renewables',
                            'real_estate',
                            'retail_and_consumer_products',
                            'retail_wholesale_and_distribution',
                            'state_local_and_higher_education',
                            'technology',
                            'telecom_media_and_entertainment',
                            'transportation_hospitality_and_services',
                            'unclassified_industries',
                          ],
                          type: 'string',
                        },
                        { type: 'null' },
                      ],
                    },
                    offeringPortfolio: {
                      anyOf: [
                        {
                          enum: [
                            '',
                            'client_and_market_growth',
                            'core_business_operations',
                            'customer',
                            'customer_and_marketing',
                            'dc_human_capital',
                            'dc_technology',
                            'enterprise_operations',
                            'gps_cmg',
                            'gps_compliance',
                            'gps_consulting_natl_office',
                            'gps_contracts',
                            'gps_dc_management',
                            'gps_ea_management',
                            'gps_enabling_areas',
                            'gps_finance',
                            'gps_general_counsel',
                            'gps_operations',
                            'gps_talent',
                            'human_capital',
                            'innovation_and_technology',
                            'internal_services',
                            'mergers_and_acquisitions',
                            'national_consulting',
                            'national_consulting_management',
                            'nbi',
                            'not_applicable',
                            'platforms',
                            'strategy_and_analytics',
                          ],
                          type: 'string',
                        },
                        { type: 'null' },
                      ],
                    },
                    itar: { type: 'boolean' },
                    limitGigWorkerLevels: {
                      $ref: 'defs.json#/definitions/DelioitteGigWorkerLevel',
                    },
                    audience: {
                      $ref: 'defs.json#/definitions/DeloitteAudienceType',
                    },
                    budget: {
                      type: 'object',
                      properties: {
                        minimum: { type: ['null', 'number'] },
                        maximum: { type: ['null', 'number'] },
                        fundingRequired: { type: ['null', 'boolean'] },
                      },
                      required: ['maximum', 'minimum'],
                    },
                    ppmdEmailAddress: { type: ['null', 'string'] },
                    externalDescription: { type: ['null', 'string'] },
                  },
                  required: [
                    'audience',
                    'billableHours',
                    'businessLine',
                    'contribution',
                    'deloitteProjectType',
                    'industrySector',
                    'isCompletionDateOngoing',
                    'isOnBehalf',
                    'itar',
                    'limitGigWorkerLevels',
                    'offeringPortfolio',
                    'practiceGroup',
                    'startDate',
                    'wbsCode',
                  ],
                },
              ],
            },
            { type: 'null' },
          ],
        },
        manageRecentTable: {
          anyOf: [
            { $ref: 'defs.json#/definitions/ManageRecentTable' },
            { type: 'null' },
          ],
        },
        inviteToBidDiscardedFreelancers: {
          type: 'object',
          additionalProperties: false,
          patternProperties: {
            '^[0-9]+$': { type: 'array', items: { type: 'number' } },
          },
        },
        flCardsExpandedStatus: {
          type: 'object',
          additionalProperties: { type: 'boolean' },
        },
        abTestVariations: {
          $ref: 'defs.json#/definitions/ABTestVariationCache',
        },
        viewedItems: {
          $ref: 'defs.json#/definitions/Partial<{project:ItemsViewedMap;}>',
        },
        hideMobileNewMessageToast: { type: 'boolean' },
        searchServiceFilters: { $ref: 'defs.json#/definitions/SearchFilters' },
        freelancerOnboardingResumeUploaded: {
          description:
            'Used on the CV upload step of the freelancer onboarding flow\nTODO: T236205 Remove after endpoint allows fetching the uploaded file',
          type: 'boolean',
        },
        freelancerOnboardingParsedResume: {
          anyOf: [
            { $ref: 'defs.json#/definitions/UserParsedResume' },
            { type: 'null' },
          ],
        },
        freelancerOnboardingResumeParsingStatus: {
          anyOf: [
            { enum: ['error', 'in_progress', 'success'], type: 'string' },
            { type: 'null' },
          ],
        },
        deviceId: { type: ['null', 'string'] },
        dontAskAgainLastChecked: { type: 'number' },
        nativeRatingPrompt: {
          type: 'object',
          properties: {
            lastPrompted: {
              type: 'object',
              properties: {
                date: { type: 'number' },
                leftFeedbackOrRating: { type: 'boolean' },
              },
              required: ['date', 'leftFeedbackOrRating'],
            },
            oneOff: {
              type: 'object',
              properties: {
                acceptProjectOrMilestoneRelease: { type: 'boolean' },
              },
            },
          },
          required: ['lastPrompted', 'oneOff'],
        },
        lastUsedForms: { $ref: 'defs.json#/definitions/LastUsedForms' },
        testProperty: { type: 'number' },
        testName: { type: ['null', 'string'] },
        testProfile: { $ref: 'defs.json#/definitions/MockProfile' },
        externalQuotation: {
          anyOf: [
            { $ref: 'defs.json#/definitions/ExternalQuotation' },
            { type: 'null' },
          ],
        },
        threeDSChallengeCookie: { type: 'string' },
        notificationsRequested: { type: 'boolean' },
        loadIdPendingAction: { type: ['null', 'number'] },
        groupsHomepageSelectedView: {
          $ref: 'defs.json#/definitions/GroupsViewFilterSelection',
        },
        waitingScreenSteps: {
          $ref: 'defs.json#/definitions/WaitingScreenSteps',
        },
        giveGetReferrerUsername: { type: ['null', 'string'] },
        giveGetFooterClosed: { type: 'boolean' },
        aiPromptSelection: { type: ['null', 'number'] },
        aiPromptEnabled: { type: 'boolean' },
        aiConsultantChatHistory: {
          anyOf: [
            {
              type: 'array',
              items: { $ref: 'defs.json#/definitions/AiConsultantMessage' },
            },
            { type: 'null' },
          ],
        },
        aiConsultantGeneratedProjectDraft: {
          anyOf: [
            {
              $ref: 'defs.json#/definitions/AiConsultantGeneratedProjectDraft',
            },
            { type: 'null' },
          ],
        },
        aiPlaygroundConversationStorage: {
          anyOf: [
            {
              type: 'array',
              items: {
                $ref: 'defs.json#/definitions/AiPlaygroundConversationSaveFile',
              },
            },
            { type: 'null' },
          ],
        },
        aiPlaygroundSavedTools: {
          anyOf: [
            {
              type: 'array',
              items: { $ref: 'defs.json#/definitions/AiPlaygroundSavedTool' },
            },
            { type: 'null' },
          ],
        },
        theme: {
          anyOf: [
            { enum: ['dark', 'light', 'system'], type: 'string' },
            { type: 'null' },
          ],
        },
        userOriginalTheme: {
          anyOf: [
            { enum: ['dark', 'light'], type: 'string' },
            { type: 'null' },
          ],
        },
        appInstallPromptDismissals: {
          $ref: 'defs.json#/definitions/AppInstallPromptDismissals',
        },
        appInstallPromptEmailDismissalDays: { type: 'number' },
      },
      required: [
        'abTestVariations',
        'aiConsultantChatHistory',
        'aiConsultantGeneratedProjectDraft',
        'aiPlaygroundConversationStorage',
        'aiPlaygroundSavedTools',
        'aiPromptEnabled',
        'aiPromptSelection',
        'appInstallPromptDismissals',
        'appInstallPromptEmailDismissalDays',
        'complianceQueueApproveDenyFormStates',
        'dashboardMyProjectsUserType',
        'deloitteDemo',
        'developersPreference',
        'deviceId',
        'dontAskAgainLastChecked',
        'enterpriseContactFormSubmitted',
        'externalQuotation',
        'flCardsExpandedStatus',
        'freelancerOnboardingParsedResume',
        'freelancerOnboardingResumeParsingStatus',
        'freelancerOnboardingResumeUploaded',
        'giveGetFooterClosed',
        'giveGetReferrerUsername',
        'groupsHomepageSelectedView',
        'hideMobileNewMessageToast',
        'hireMeDraft',
        'inviteToBidDiscardedFreelancers',
        'lastSeenPjpAssistant',
        'lastUsedForms',
        'loadIdPendingAction',
        'manageRecentTable',
        'nativeRatingPrompt',
        'navNotificationUpdatesLastReadTime',
        'navSavedAlertsLastReadTime',
        'navUpdatesLastReadTime',
        'notificationsRequested',
        'postJobPageDraft',
        'postProjectObject',
        'projectOverlayFullFlowComplete',
        'projectOverlayThreadComplete',
        'searchServiceFilters',
        'taskList',
        'taskListCurrentTaskClean',
        'testName',
        'testProfile',
        'testProperty',
        'theme',
        'threeDSChallengeCookie',
        'userOriginalTheme',
        'viewedItems',
        'waitingScreenSteps',
        'webappChatDraftMessages',
        'webappChats',
        'webappThreadListMinimise',
      ],
    },
    DevelopersPreference: {
      type: 'object',
      properties: {
        language: { type: 'string', enum: ['bash'] },
        environment: { $ref: 'defs.json#/definitions/DeveloperEnvironment' },
      },
      required: ['environment', 'language'],
    },
    DeveloperEnvironment: {
      enum: ['Dev', 'Production', 'Sandbox', 'Staging'],
      type: 'string',
    },
    ProjectViewProject: {
      description:
        'A project.\nUsed on the Freelancer Project View Page.\n\nProjections: `attachment_details`, `full_description`, `job_details`,\n`location_details`, `qualification_details`, `upgrade_details`,\n`nda_details`, `project_collaboration_details`, `upgrade_details`,\n`review_availability_details`, `local_details`, `equipment`',
      type: 'object',
      properties: {
        attachments: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/ProjectAttachment' },
        },
        canPostReview: { $ref: 'defs.json#/definitions/ReviewAvailabilityApi' },
        description: { type: 'string' },
        skills: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/Skill' },
        },
        location: { $ref: 'defs.json#/definitions/Location' },
        true_location: { $ref: 'defs.json#/definitions/Location' },
        ndaDetails: { $ref: 'defs.json#/definitions/NDADetails' },
        qualifications: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/Qualification' },
        },
        displayLocation: { type: 'string' },
        localDetails: { $ref: 'defs.json#/definitions/ProjectLocalDetails' },
        equipment: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/EquipmentApi_1' },
        },
        invitedFreelancers: { type: 'array', items: { type: 'number' } },
        repostId: { type: 'number' },
        hiremeOpenedForBidding: { type: 'boolean' },
        clientEngagement: {
          $ref: 'defs.json#/definitions/ProjectClientEngagement',
        },
        contractSignatures: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/ProjectContractSignatures' },
        },
        enterpriseLinkedProjectsDetails: {
          description:
            'Structure depends on whether the project is internal or external',
          anyOf: [
            {
              $ref: 'defs.json#/definitions/EnterpriseLinkedInternalProjectDetails',
            },
            {
              $ref: 'defs.json#/definitions/EnterpriseLinkedExternalProjectDetails',
            },
          ],
        },
        equipmentGroups: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/EquipmentGroup' },
        },
        serviceOfferingId: { type: 'number' },
        bidPeriod: { type: 'number' },
        bidStats: { $ref: 'defs.json#/definitions/PartialProjectBidStats' },
        budget: { $ref: 'defs.json#/definitions/ProjectBudget' },
        currency: { $ref: 'defs.json#/definitions/Currency' },
        deleted: { type: 'boolean' },
        frontendProjectStatus: {
          description:
            'WARNING: Do not rely on this in tests or in the app. It can be broken for projects\nthat move backwards in the project flow at any point (e.g. cancelled then reawarded),\nand multi-award. Use ProjectStatusHelper instead.',
          enum: ['complete', 'draft', 'open', 'pending', 'work_in_progress'],
          type: 'string',
        },
        hideBids: { type: 'boolean' },
        hireme: { type: 'boolean' },
        hiremeInitialBid: { $ref: 'defs.json#/definitions/HireMeInitialBid' },
        isQuotationProject: { type: 'boolean' },
        quotationInitialBid: {
          $ref: 'defs.json#/definitions/QuotationInitialBid',
        },
        hourlyProjectInfo: {
          $ref: 'defs.json#/definitions/ProjectHourlyProjectInfo',
        },
        id: { type: 'number' },
        language: { type: 'string' },
        local: { type: 'boolean' },
        ownerId: { type: 'number' },
        previewDescription: { type: 'string' },
        projectCollaborations: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/ProjectCollaborations' },
        },
        seoUrl: { type: 'string' },
        status: { $ref: 'defs.json#/definitions/ProjectStatusApi' },
        subStatus: {
          description:
            "ProjectSubStatusApi.FROZEN_AWARDED - Waiting for response on award, frozen\nProjectSubStatusApi.CLOSED_AWARDED - Awarded by employer for any freelancer, revoke doesn't count\nProjectSubStatusApi.CANCEL_SELLER - User marks it as incomplete",
          enum: [
            'cancel_admin',
            'cancel_buyer',
            'cancel_seller',
            'closed_awarded',
            'closed_expired',
            'frozen_awarded',
            'frozen_timeout',
            'requires_correction',
          ],
          type: 'string',
        },
        timeSubmitted: { type: 'number' },
        title: { type: 'string' },
        type: { $ref: 'defs.json#/definitions/ProjectTypeApi' },
        upgrades: { $ref: 'defs.json#/definitions/ProjectUpgrades' },
        poolIds: { type: 'array', items: { type: 'number' } },
        groupIds: { type: 'array', items: { type: 'number' } },
        enterpriseIds: { type: 'array', items: { type: 'number' } },
        deloitteDetails: {
          $ref: 'defs.json#/definitions/DeloitteProjectDetails',
        },
        timeframe: { $ref: 'defs.json#/definitions/ProjectTimeframe' },
        isEscrowProject: { type: 'boolean' },
        isSellerKycRequired: { type: 'boolean' },
        isBuyerKycRequired: { type: 'boolean' },
        isDeloitteProject: { type: 'boolean' },
        isTokenProject: { type: 'boolean' },
        selectedBids: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/Bid' },
        },
        files: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/ProjectFileApi' },
        },
        billingCode: { type: 'string' },
        customFieldValues: {
          type: 'array',
          items: {
            description:
              'The concrete value of a custom field attached to a resource.\nEquivalent to the EnterpriseMetadataValue Thrift type.',
            anyOf: [
              {
                allOf: [
                  {
                    type: 'object',
                    properties: {
                      customFieldInfoConfigurationId: {
                        description:
                          "Refers to the CustomFieldInfoConfiguration document's ID, i.e. the\nenterprise_resource_metadata_field ID",
                        type: 'number',
                      },
                      resourceId: { type: 'number' },
                      isDefaultValue: {
                        description:
                          'Indicates if the CustomFieldValue is a default value for the custom field.\nDefault value is configurable at the backend.',
                        type: 'boolean',
                      },
                      parentId: { type: 'number' },
                      objectGroup: { type: 'number' },
                      parentObjectGroup: { type: 'number' },
                    },
                    required: [
                      'customFieldInfoConfigurationId',
                      'isDefaultValue',
                      'resourceId',
                    ],
                  },
                  {
                    type: 'object',
                    properties: {
                      type: { type: 'string', enum: ['string'] },
                      value: { type: 'string' },
                    },
                    required: ['type', 'value'],
                  },
                ],
              },
              {
                allOf: [
                  {
                    type: 'object',
                    properties: {
                      customFieldInfoConfigurationId: {
                        description:
                          "Refers to the CustomFieldInfoConfiguration document's ID, i.e. the\nenterprise_resource_metadata_field ID",
                        type: 'number',
                      },
                      resourceId: { type: 'number' },
                      isDefaultValue: {
                        description:
                          'Indicates if the CustomFieldValue is a default value for the custom field.\nDefault value is configurable at the backend.',
                        type: 'boolean',
                      },
                      parentId: { type: 'number' },
                      objectGroup: { type: 'number' },
                      parentObjectGroup: { type: 'number' },
                    },
                    required: [
                      'customFieldInfoConfigurationId',
                      'isDefaultValue',
                      'resourceId',
                    ],
                  },
                  {
                    type: 'object',
                    properties: {
                      type: { type: 'string', enum: ['boolean'] },
                      value: { type: 'boolean' },
                    },
                    required: ['type', 'value'],
                  },
                ],
              },
              {
                allOf: [
                  {
                    type: 'object',
                    properties: {
                      customFieldInfoConfigurationId: {
                        description:
                          "Refers to the CustomFieldInfoConfiguration document's ID, i.e. the\nenterprise_resource_metadata_field ID",
                        type: 'number',
                      },
                      resourceId: { type: 'number' },
                      isDefaultValue: {
                        description:
                          'Indicates if the CustomFieldValue is a default value for the custom field.\nDefault value is configurable at the backend.',
                        type: 'boolean',
                      },
                      parentId: { type: 'number' },
                      objectGroup: { type: 'number' },
                      parentObjectGroup: { type: 'number' },
                    },
                    required: [
                      'customFieldInfoConfigurationId',
                      'isDefaultValue',
                      'resourceId',
                    ],
                  },
                  {
                    type: 'object',
                    properties: {
                      type: { type: 'string', enum: ['float'] },
                      value: { type: 'number' },
                    },
                    required: ['type', 'value'],
                  },
                ],
              },
              {
                allOf: [
                  {
                    type: 'object',
                    properties: {
                      customFieldInfoConfigurationId: {
                        description:
                          "Refers to the CustomFieldInfoConfiguration document's ID, i.e. the\nenterprise_resource_metadata_field ID",
                        type: 'number',
                      },
                      resourceId: { type: 'number' },
                      isDefaultValue: {
                        description:
                          'Indicates if the CustomFieldValue is a default value for the custom field.\nDefault value is configurable at the backend.',
                        type: 'boolean',
                      },
                      parentId: { type: 'number' },
                      objectGroup: { type: 'number' },
                      parentObjectGroup: { type: 'number' },
                    },
                    required: [
                      'customFieldInfoConfigurationId',
                      'isDefaultValue',
                      'resourceId',
                    ],
                  },
                  {
                    type: 'object',
                    properties: {
                      type: { type: 'string', enum: ['integer'] },
                      value: { type: 'number' },
                    },
                    required: ['type', 'value'],
                  },
                ],
              },
              {
                allOf: [
                  {
                    type: 'object',
                    properties: {
                      customFieldInfoConfigurationId: {
                        description:
                          "Refers to the CustomFieldInfoConfiguration document's ID, i.e. the\nenterprise_resource_metadata_field ID",
                        type: 'number',
                      },
                      resourceId: { type: 'number' },
                      isDefaultValue: {
                        description:
                          'Indicates if the CustomFieldValue is a default value for the custom field.\nDefault value is configurable at the backend.',
                        type: 'boolean',
                      },
                      parentId: { type: 'number' },
                      objectGroup: { type: 'number' },
                      parentObjectGroup: { type: 'number' },
                    },
                    required: [
                      'customFieldInfoConfigurationId',
                      'isDefaultValue',
                      'resourceId',
                    ],
                  },
                  {
                    type: 'object',
                    properties: {
                      type: { type: 'string', enum: ['timestamp'] },
                      value: { type: 'number' },
                    },
                    required: ['type', 'value'],
                  },
                ],
              },
              {
                allOf: [
                  {
                    type: 'object',
                    properties: {
                      customFieldInfoConfigurationId: {
                        description:
                          "Refers to the CustomFieldInfoConfiguration document's ID, i.e. the\nenterprise_resource_metadata_field ID",
                        type: 'number',
                      },
                      resourceId: { type: 'number' },
                      isDefaultValue: {
                        description:
                          'Indicates if the CustomFieldValue is a default value for the custom field.\nDefault value is configurable at the backend.',
                        type: 'boolean',
                      },
                      parentId: { type: 'number' },
                      objectGroup: { type: 'number' },
                      parentObjectGroup: { type: 'number' },
                    },
                    required: [
                      'customFieldInfoConfigurationId',
                      'isDefaultValue',
                      'resourceId',
                    ],
                  },
                  {
                    type: 'object',
                    properties: {
                      type: { type: 'string', enum: ['location'] },
                      value: { $ref: 'defs.json#/definitions/MapCoordinates' },
                    },
                    required: ['type', 'value'],
                  },
                ],
              },
              {
                allOf: [
                  {
                    type: 'object',
                    properties: {
                      customFieldInfoConfigurationId: {
                        description:
                          "Refers to the CustomFieldInfoConfiguration document's ID, i.e. the\nenterprise_resource_metadata_field ID",
                        type: 'number',
                      },
                      resourceId: { type: 'number' },
                      isDefaultValue: {
                        description:
                          'Indicates if the CustomFieldValue is a default value for the custom field.\nDefault value is configurable at the backend.',
                        type: 'boolean',
                      },
                      parentId: { type: 'number' },
                      objectGroup: { type: 'number' },
                      parentObjectGroup: { type: 'number' },
                    },
                    required: [
                      'customFieldInfoConfigurationId',
                      'isDefaultValue',
                      'resourceId',
                    ],
                  },
                  {
                    type: 'object',
                    properties: {
                      type: { type: 'string', enum: ['undefined'] },
                      value: { type: 'undefined' },
                    },
                    required: ['type', 'value'],
                  },
                ],
              },
              {
                allOf: [
                  {
                    type: 'object',
                    properties: {
                      customFieldInfoConfigurationId: {
                        description:
                          "Refers to the CustomFieldInfoConfiguration document's ID, i.e. the\nenterprise_resource_metadata_field ID",
                        type: 'number',
                      },
                      resourceId: { type: 'number' },
                      isDefaultValue: {
                        description:
                          'Indicates if the CustomFieldValue is a default value for the custom field.\nDefault value is configurable at the backend.',
                        type: 'boolean',
                      },
                      parentId: { type: 'number' },
                      objectGroup: { type: 'number' },
                      parentObjectGroup: { type: 'number' },
                    },
                    required: [
                      'customFieldInfoConfigurationId',
                      'isDefaultValue',
                      'resourceId',
                    ],
                  },
                  {
                    type: 'object',
                    properties: {
                      type: { type: 'string', enum: ['object'] },
                      value: { type: 'number' },
                    },
                    required: ['type'],
                  },
                ],
              },
            ],
          },
        },
        jobs: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/JobApi' },
        },
        isInsourceProject: { type: 'boolean' },
        hireMeQuotation: { $ref: 'defs.json#/definitions/HireMeQuotation' },
        projectSource: { type: 'string' },
        projectSourceReference: { type: 'string' },
        isHpProject: { type: 'boolean' },
      },
      required: [
        'attachments',
        'bidStats',
        'budget',
        'canPostReview',
        'currency',
        'customFieldValues',
        'deleted',
        'hideBids',
        'hireme',
        'id',
        'isBuyerKycRequired',
        'isDeloitteProject',
        'isEscrowProject',
        'isHpProject',
        'isInsourceProject',
        'isSellerKycRequired',
        'isTokenProject',
        'language',
        'local',
        'ndaDetails',
        'ownerId',
        'previewDescription',
        'projectCollaborations',
        'qualifications',
        'selectedBids',
        'seoUrl',
        'skills',
        'status',
        'timeSubmitted',
        'title',
        'type',
        'upgrades',
      ],
    },
    ProjectAttachment: {
      type: 'object',
      properties: {
        id: { type: 'number' },
        accessHash: { type: 'string' },
        contentType: { type: 'string' },
        filename: { type: 'string' },
        timeSubmitted: { type: 'number' },
        url: { type: 'string' },
      },
      required: ['id'],
    },
    ReviewAvailabilityApi: {
      type: 'object',
      properties: {
        freelancer: { type: 'boolean' },
        employer: { type: 'boolean' },
      },
    },
    Skill: {
      description:
        'A skill possessed by a freelancer, and required by projects to allow bidding.\n\nTODO T72831: Confusingly the API calls this a Job, conflicting with the Post\nJob Page which posts a project or contest, among others.',
      type: 'object',
      properties: {
        id: { type: 'number' },
        name: { type: 'string' },
        category: { $ref: 'defs.json#/definitions/Category' },
        local: { type: 'boolean' },
        seoUrl: { type: 'string' },
        activeProjectCount: { type: 'number' },
        questions: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/SkillQuestion' },
        },
      },
      required: ['activeProjectCount', 'id', 'local', 'name', 'seoUrl'],
    },
    Category: {
      type: 'object',
      properties: { id: { type: 'number' }, name: { type: 'string' } },
      required: ['id'],
    },
    SkillQuestion: {
      type: 'object',
      properties: {
        id: { type: 'number' },
        internalName: { type: 'string' },
        displayName: { type: 'string' },
        contextType: { type: 'string', enum: ['delivery_item'] },
      },
      required: ['contextType', 'displayName', 'id', 'internalName'],
    },
    Location: {
      description:
        "A project's location details.\nUsed on the Project View Page and User Profiles",
      type: 'object',
      properties: {
        country: { $ref: 'defs.json#/definitions/Country' },
        city: { type: 'string' },
        mapCoordinates: { $ref: 'defs.json#/definitions/MapCoordinates' },
        vicinity: { type: 'string' },
        administrativeArea: { type: 'string' },
        administrativeAreaCode: { type: 'string' },
        fullAddress: { type: 'string' },
        postalCode: { type: 'string' },
        timezone: { $ref: 'defs.json#/definitions/Timezone' },
      },
    },
    Country: {
      description: 'A country.',
      type: 'object',
      properties: {
        id: { type: 'string' },
        code: { type: 'string' },
        name: { type: 'string' },
        phoneCode: {
          description: 'only present when fetching the `countries` collection',
          type: 'number',
        },
        flagUrl: {
          description:
            "only present when fetching a user's location, e.g. `projectViewUsers`",
          type: 'string',
        },
      },
      required: ['code', 'id', 'name'],
    },
    MapCoordinates: {
      type: 'object',
      properties: {
        latitude: { type: 'number' },
        longitude: { type: 'number' },
      },
      required: ['latitude', 'longitude'],
    },
    Timezone: {
      description: "Timezones — used to determine user's time offset",
      type: 'object',
      properties: {
        id: { type: 'number' },
        country: { type: 'string' },
        timezoneName: { type: 'string' },
        offset: { type: 'string' },
      },
      required: ['country', 'id', 'offset', 'timezoneName'],
    },
    NDADetails: {
      type: 'object',
      properties: {
        hiddenDescription: { type: 'string' },
        signatures: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/NDASignature' },
        },
      },
      required: ['signatures'],
    },
    NDASignature: {
      type: 'object',
      properties: {
        timeSigned: { type: 'number' },
        projectId: { type: 'number' },
        userId: { type: 'number' },
      },
      required: ['projectId', 'timeSigned', 'userId'],
    },
    Qualification: {
      type: 'object',
      properties: {
        id: { type: 'number' },
        description: { type: 'string' },
        iconName: { type: 'string' },
        iconUrl: { type: 'string' },
        insigniaId: { type: 'number' },
        level: { type: 'number' },
        name: { type: 'string' },
        scorePercentage: { type: 'number' },
        type: { type: 'string' },
        userPercentile: { type: 'number' },
      },
    },
    ProjectLocalDetails: {
      type: 'object',
      properties: {
        date: { $ref: 'defs.json#/definitions/ProjectLocalDetailsDateApi' },
        dateTimestamp: { type: 'number' },
        endLocation: { $ref: 'defs.json#/definitions/Location' },
        displayEndLocation: { type: 'string' },
        workStartDate: { type: 'number' },
        workEndDate: { type: 'number' },
        distance: { type: 'number' },
        travelTime: { type: 'number' },
        deliveryType: {
          enum: ['estimate_only', 'general', 'hotshot', 'project'],
          type: 'string',
        },
        deliveryItems: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/DeliveryItem' },
        },
        pickupDeliveryContact: {
          $ref: 'defs.json#/definitions/DeliveryContactDetails',
        },
        dropoffDeliveryContact: {
          $ref: 'defs.json#/definitions/DeliveryContactDetails',
        },
        isAsapDropoff: { type: 'boolean' },
      },
    },
    ProjectLocalDetailsDateApi: {
      type: 'object',
      properties: {
        year: { type: 'number' },
        month: { type: 'number' },
        day: { type: 'number' },
      },
      required: ['day', 'month', 'year'],
    },
    DeliveryItem: {
      type: 'object',
      properties: {
        id: { type: 'number' },
        referenceNumber: { type: 'string' },
        description: { type: 'string' },
        quantity: { type: 'number' },
        width: { type: 'number' },
        length: { type: 'number' },
        height: { type: 'number' },
        weight: { type: 'number' },
        attachments: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/Attachment' },
        },
        skill: { $ref: 'defs.json#/definitions/Skill' },
        skillAnswers: {
          type: 'object',
          additionalProperties: false,
          patternProperties: {
            '^[0-9]+$': {
              type: 'array',
              items: { $ref: 'defs.json#/definitions/SkillAnswer' },
            },
          },
        },
      },
      required: ['id', 'quantity'],
    },
    Attachment: {
      type: 'object',
      properties: {
        id: { type: 'number' },
        attachedToType: {
          $ref: 'defs.json#/definitions/AttachmentAttachedToTypeApi',
        },
        attachedToId: { type: 'number' },
        attachedObjectType: {
          $ref: 'defs.json#/definitions/AttachmentTypeApi',
        },
        attachedObjectId: { type: 'string' },
        attachedByType: { type: 'string', enum: ['user'] },
        attachedById: { type: 'number' },
        attachmentUuid: { type: 'string' },
        created: { type: 'number' },
        position: { type: 'number' },
      },
      required: [
        'attachedByType',
        'attachedObjectType',
        'attachedToType',
        'created',
        'id',
        'position',
      ],
    },
    AttachmentAttachedToTypeApi: {
      enum: ['delivery_item', 'post', 'task', 'user_availability'],
      type: 'string',
    },
    AttachmentTypeApi: {
      enum: ['drive_file', 'file', 'project'],
      type: 'string',
    },
    SkillAnswer: {
      type: 'object',
      properties: {
        id: { type: 'number' },
        questionId: { type: 'number' },
        content: { type: 'string' },
        contextId: { type: 'number' },
      },
      required: ['content', 'contextId', 'id', 'questionId'],
    },
    DeliveryContactDetails: {
      type: 'object',
      properties: {
        id: { type: 'number' },
        fullname: { type: 'string' },
        phoneCode: { type: 'string' },
        phoneNumber: { type: 'string' },
        siteType: { type: 'string' },
        loadingFacilities: {
          $ref: 'defs.json#/definitions/EquipmentGroupItemsApi',
        },
        notes: { type: 'string' },
      },
    },
    EquipmentGroupItemsApi: {
      type: 'object',
      properties: {
        id: { type: 'number' },
        items: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/EquipmentApi' },
        },
      },
      required: ['id'],
    },
    EquipmentApi: {
      type: 'object',
      properties: { id: { type: 'number' }, name: { type: 'string' } },
      required: ['id'],
    },
    EquipmentApi_1: {
      type: 'object',
      properties: { id: { type: 'number' }, name: { type: 'string' } },
      required: ['id'],
    },
    ProjectClientEngagement: {
      type: 'object',
      properties: {
        chatsInitiated: { type: 'number' },
        invites: { type: 'number' },
      },
      required: ['chatsInitiated', 'invites'],
    },
    ProjectContractSignatures: {
      type: 'object',
      properties: {
        contractType: { $ref: 'defs.json#/definitions/ContractTypeApi' },
        contextType: { $ref: 'defs.json#/definitions/ContractContextTypeApi' },
        contextId: { type: 'number' },
        displayName: { type: 'string' },
        userIdTimeSignedMap: {
          type: 'object',
          additionalProperties: false,
          patternProperties: { '^[0-9]+$': { type: 'number' } },
        },
        customContractId: { type: 'number' },
      },
      required: [
        'contextId',
        'contextType',
        'contractType',
        'customContractId',
        'displayName',
        'userIdTimeSignedMap',
      ],
    },
    ContractTypeApi: {
      enum: [
        'ato_no_abn_required_declaration',
        'client_agreement',
        'contest_ip',
        'ip',
        'nda',
      ],
      type: 'string',
    },
    ContractContextTypeApi: {
      enum: ['contest_entry', 'project'],
      type: 'string',
    },
    EnterpriseLinkedInternalProjectDetails: {
      type: 'object',
      properties: {
        isInternal: {
          description:
            'Union discriminator for type narrowing - computed field',
          type: 'boolean',
          enum: [true],
        },
        externalProjects: {
          description:
            'Every internal project is guaranteed to have at least one external project,\neven if it is not dual-posted',
          type: 'array',
          items: { $ref: 'defs.json#/definitions/EnterpriseExternalProject' },
        },
        latestExternalProject: {
          $ref: 'defs.json#/definitions/EnterpriseExternalProject',
        },
        approvalStatus: {
          $ref: 'defs.json#/definitions/EnterpriseLinkedProjectsApprovalStatusApi',
        },
      },
      required: [
        'approvalStatus',
        'externalProjects',
        'isInternal',
        'latestExternalProject',
      ],
    },
    EnterpriseExternalProject: {
      type: 'object',
      properties: {
        id: { type: 'number' },
        budget: { $ref: 'defs.json#/definitions/ProjectBudget' },
        poolIds: { type: 'array', items: { type: 'number' } },
        isDualPosted: { type: 'boolean' },
        description: { type: 'string' },
      },
      required: ['id', 'isDualPosted', 'poolIds'],
    },
    ProjectBudget: {
      type: 'object',
      properties: {
        minimum: { type: 'number' },
        maximum: { type: 'number' },
        name: { type: 'string' },
        projectType: { enum: ['fixed', 'hourly'], type: 'string' },
        currencyId: { type: 'number' },
      },
      required: ['minimum'],
    },
    EnterpriseLinkedProjectsApprovalStatusApi: {
      enum: ['approved', 'pending', 'rejected'],
      type: 'string',
    },
    EnterpriseLinkedExternalProjectDetails: {
      type: 'object',
      properties: {
        isInternal: {
          description:
            'Union discriminator for type narrowing - computed field',
          type: 'boolean',
          enum: [false],
        },
        internalProjectId: {
          description:
            'Every external project is linked to exactly one internal project',
          type: 'number',
        },
        isDualPosted: {
          description:
            'Indicates whether the project is dual-posted.\nIf dual-posted, the external project will activate after approval.\nIf not dual-posted, after pre-approval the external project will stay as pending until\nit gets activated by the project poster manually.',
          type: 'boolean',
        },
        rejectReasons: {
          description:
            'Provided by the compliance queue agent when they deny the project.\nEmpty if the project is pending or accepted in the compliance queue.',
          type: 'array',
          items: {
            $ref: 'defs.json#/definitions/ExternalProjectRejectReasonApi',
          },
        },
        rejectNote: {
          description:
            'Provided by the compliance queue agent when they deny the project.\nNot present if the project is pending or accepted in the compliance queue.',
          type: 'string',
        },
      },
      required: ['internalProjectId', 'isDualPosted', 'isInternal'],
    },
    ExternalProjectRejectReasonApi: {
      type: 'object',
      properties: { id: { type: 'number' }, description: { type: 'string' } },
      required: ['description', 'id'],
    },
    EquipmentGroup: {
      description:
        'Equipment group\nCalls to the `/equipment_groups` endpoint\nResponsible for retrieving equipment group attached to enterprise',
      type: 'object',
      properties: {
        id: { type: 'number' },
        name: { type: 'string' },
        hasUserInput: { type: 'boolean' },
        isProjectRequired: { type: 'boolean' },
        isUserRequired: { type: 'boolean' },
        items: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/EquipmentItem' },
        },
      },
      required: [
        'hasUserInput',
        'id',
        'isProjectRequired',
        'isUserRequired',
        'name',
      ],
    },
    EquipmentItem: {
      type: 'object',
      properties: { id: { type: 'number' }, name: { type: 'string' } },
      required: ['id'],
    },
    PartialProjectBidStats: {
      description:
        'Contains partial project bid stats, excluding some details like min/max bid amount and period.\nSee ProjectBidStats for full list of stats.',
      type: 'object',
      properties: { bidCount: { type: 'number' }, bidAvg: { type: 'number' } },
      required: ['bidCount'],
    },
    Currency: {
      description:
        'A supported currency, pulled from the API.\nSee `currenciesIncludingExternal` for the projection that includes external currencies.',
      type: 'object',
      properties: {
        code: { type: 'string' },
        country: { type: 'string' },
        id: { type: 'number' },
        name: { type: 'string' },
        sign: { type: 'string' },
        isExternal: { type: 'boolean' },
        exchangeRate: { type: 'number' },
      },
      required: ['code', 'id', 'sign'],
    },
    HireMeInitialBid: {
      type: 'object',
      properties: {
        bidderId: { type: 'number' },
        amount: { type: 'number' },
        period: { type: 'number' },
      },
      required: ['amount', 'bidderId', 'period'],
    },
    QuotationInitialBid: {
      type: 'object',
      properties: {
        bidderId: { type: 'number' },
        amount: { type: 'number' },
        period: { type: 'number' },
      },
      required: ['amount', 'bidderId', 'period'],
    },
    ProjectHourlyProjectInfo: {
      type: 'object',
      properties: {
        commitment: { $ref: 'defs.json#/definitions/CommitmentApi' },
        durationEnum: {
          enum: [
            'less_than_one_week',
            'one_to_four_weeks',
            'one_to_three_months',
            'ongoing',
            'three_to_six_months',
            'unknown',
            'unspecified',
          ],
          type: 'string',
        },
      },
    },
    CommitmentApi: {
      type: 'object',
      properties: {
        hours: { type: 'number' },
        interval: { $ref: 'defs.json#/definitions/TimeUnitApi' },
      },
      required: ['hours', 'interval'],
    },
    TimeUnitApi: {
      enum: [
        'century',
        'day',
        'decade',
        'hour',
        'millenium',
        'minute',
        'month',
        'second',
        'week',
        'year',
      ],
      type: 'string',
    },
    ProjectCollaborations: {
      type: 'object',
      properties: {
        context: { $ref: 'defs.json#/definitions/Context' },
        contextOwnerId: { type: 'number' },
        id: { type: 'number' },
        inviteToken: { type: 'string' },
        permissions: {
          type: 'array',
          items: {
            enum: [
              'bid_award',
              'chat',
              'collaborator_add',
              'dispute_create',
              'file_upload',
              'milestone_create',
              'milestone_release',
              'milestone_request',
              'milestone_view',
              'project_details_edit',
              'task_list_edit',
              'tracked_hours_approve',
              'unknown',
            ],
            type: 'string',
          },
        },
        status: {
          enum: [
            'accept_pending',
            'active',
            'inactive',
            'signup_pending',
            'unknown',
          ],
          type: 'string',
        },
        timeCreated: { type: 'number' },
        timeUpdated: { type: 'number' },
        userId: { type: 'number' },
      },
      required: ['id', 'permissions'],
    },
    Context: {
      type: 'object',
      properties: {
        id: { type: 'number' },
        type: { $ref: 'defs.json#/definitions/ContextTypeApi' },
      },
      required: ['id', 'type'],
    },
    ContextTypeApi: { enum: ['contest', 'none', 'project'], type: 'string' },
    ProjectStatusApi: {
      enum: ['active', 'closed', 'draft', 'frozen', 'pending', 'rejected'],
      type: 'string',
    },
    ProjectTypeApi: { enum: ['fixed', 'hourly'], type: 'string' },
    ProjectUpgrades: {
      type: 'object',
      properties: {
        assisted: { type: 'boolean' },
        featured: { type: 'boolean' },
        fulltime: { type: 'boolean' },
        ipContract: { type: 'boolean' },
        listed: { type: 'boolean' },
        NDA: { type: 'boolean' },
        nonpublic: { type: 'boolean' },
        projectManagement: { type: 'boolean' },
        qualified: { type: 'boolean' },
        sealed: { type: 'boolean' },
        urgent: { type: 'boolean' },
        extend: { type: 'boolean' },
        unpaidRecruiter: { type: 'boolean' },
        pfOnly: { type: 'boolean' },
        premium: { type: 'boolean' },
        enterprise: { type: 'boolean' },
      },
      required: [
        'NDA',
        'assisted',
        'extend',
        'featured',
        'fulltime',
        'ipContract',
        'listed',
        'nonpublic',
        'projectManagement',
        'qualified',
        'sealed',
        'urgent',
      ],
    },
    DeloitteProjectDetails: {
      description: 'Deloitte project details found in a Deloitte project',
      type: 'object',
      properties: {
        billingCode: { type: 'string' },
        industryOffering: {
          $ref: 'defs.json#/definitions/DeloitteIndustryOffering',
        },
        clearance: { type: 'string' },
        itar: { type: 'boolean' },
      },
      required: ['billingCode', 'industryOffering'],
    },
    DeloitteIndustryOffering: {
      type: 'object',
      properties: {
        projectType: { $ref: 'defs.json#/definitions/DeloitteProjectTypeApi' },
        practice: {
          enum: [
            'commercial_ardc',
            'commercial_core',
            'commercial_usdc_incenter',
            'commercial_usdc_onsite',
            'gps_core',
            'gps_operational_excellence',
            'gps_usdc_incenter',
            'gps_usdc_onsite',
            'gps_usi_offshore',
            'gps_usi_onsite',
            'usi_offshore',
            'usi_onsite',
          ],
          type: 'string',
        },
        industry: { $ref: 'defs.json#/definitions/DeloitteIndustryApi' },
        offering: {
          enum: [
            'cbo_cloud_engineering',
            'cbo_core_industry_solutions',
            'cbo_cross_consulting_group',
            'cbo_health_technology',
            'cbo_operations_transformation',
            'cbo_other',
            'cbo_systems_engineering',
            'cnm_advertising_marketing_and_commerce',
            'cnm_asset_group',
            'cnm_cross_consulting_group',
            'cnm_customer_strategy_and_applied_design',
            'cnm_digital_customer',
            'cnm_human_services_transformation',
            'cnm_other',
            'eo_asset_group',
            'eo_cross_consulting_group',
            'eo_erp_saas',
            'eo_finance_and_enterprise_performance',
            'eo_oracle',
            'eo_other',
            'eo_sap',
            'eo_supply_chain_and_network_operations',
            'eo_technology_services_optimization',
            'gpsea_gps_client_excellence',
            'gpsea_gps_compliance',
            'gpsea_gps_contracts',
            'gpsea_gps_ea_management',
            'gpsea_gps_finance',
            'gpsea_gps_general_counsel',
            'gpsea_gps_operations',
            'gpsea_gps_talent',
            'hc_asset_group',
            'hc_cross_consulting_group',
            'hc_hc_as_a_service',
            'hc_hr_transformation',
            'hc_organization_transformation',
            'hc_other',
            'hc_workforce_transformation',
            'is_gps_business_services',
            'is_gps_business_systems',
            'is_gps_compliance',
            'is_gps_contracts',
            'is_gps_finance',
            'is_gps_ops_management',
            'is_gps_security',
            'is_gps_talent',
            'marketing_excellence',
            'mna_ccg',
            'mna_integration_and_divesture',
            'mna_mergers_and_acquisitions',
            'mna_other',
            'nc_adjustments_or_other',
            'nc_alliances',
            'nc_c_suite',
            'nc_client_and_industry',
            'nc_client_excellence',
            'nc_cloud',
            'nc_corporate_development',
            'nc_delivery_excellence',
            'nc_dtoc',
            'nc_international',
            'nc_n_o_global_consulting_office',
            'nc_national_consulting',
            'nc_offering_portfolio',
            'nc_operation_and_finance',
            'nc_other_usi',
            'nc_partner_matters',
            'nc_sales_and_pursuit_excellence',
            'nc_strategy',
            'nc_talent',
            'nc_tech_and_innovation',
            'p_organic',
            'p_snet',
            'sna_analytics_and_cognitive',
            'sna_asset_group',
            'sna_cross_consulting_group',
            'sna_hybrid_solutions_and_incubation',
            'sna_other',
            'sna_strategy',
          ],
          type: 'string',
        },
      },
      required: ['industry', 'projectType'],
    },
    DeloitteProjectTypeApi: {
      enum: [
        'client_billable',
        'firm_initiative',
        'internal_services',
        'marketplace_or_firm_contribution',
        'other_investment',
        'prd',
        'skills_based_volunteering',
      ],
      type: 'string',
    },
    DeloitteIndustryApi: {
      enum: [
        'automotive',
        'automotive_transportation_hospitality_and_services',
        'banking_and_capital_markets',
        'civil_government',
        'consumer_products',
        'cross_sector_c',
        'cross_sector_ci',
        'cross_sector_eri',
        'cross_sector_fs',
        'cross_sector_gps',
        'cross_sector_lshc',
        'cross_sector_tmt',
        'defense_security_and_justice',
        'energy_and_chemicals',
        'federal_health',
        'health_care',
        'holding_companies',
        'individuals_and_estates',
        'industrial_products_and_construction',
        'industry',
        'insurance',
        'investment_management',
        'investment_management_and_real_estate',
        'life_sciences',
        'media_and_entertainment',
        'not_applicable',
        'not_assigned',
        'oil_gas_and_chemicals',
        'power_and_utilities',
        'power_utilities_and_renewables',
        'real_estate',
        'retail_and_consumer_products',
        'retail_wholesale_and_distribution',
        'state_local_and_higher_education',
        'technology',
        'telecom_media_and_entertainment',
        'transportation_hospitality_and_services',
        'unclassified_industries',
      ],
      type: 'string',
    },
    ProjectTimeframe: {
      type: 'object',
      properties: {
        startDate: { type: 'number' },
        endDate: { type: 'number' },
      },
    },
    Bid: {
      description: 'A bid placed on a project.',
      type: 'object',
      properties: {
        id: { type: 'number' },
        bidderId: { type: 'number' },
        projectId: { type: 'number' },
        retracted: { type: 'boolean' },
        amount: { type: 'number' },
        period: { type: 'number' },
        description: { type: 'string' },
        projectOwnerId: { type: 'number' },
        submitDate: { type: 'number' },
        buyerProjectFee: { $ref: 'defs.json#/definitions/Fee' },
        timeSubmitted: { type: 'number' },
        highlighted: { type: 'boolean' },
        sponsored: { type: 'number' },
        milestonePercentage: { type: 'number' },
        awardStatusPossibilities: {
          type: 'array',
          items: {
            enum: ['awarded', 'canceled', 'pending', 'rejected', 'revoked'],
            type: 'string',
          },
        },
        awardStatus: {
          enum: ['awarded', 'canceled', 'pending', 'rejected', 'revoked'],
          type: 'string',
        },
        paidStatus: {
          enum: ['fully_paid', 'partly_paid', 'unpaid'],
          type: 'string',
        },
        completeStatus: {
          enum: ['complete', 'incomplete', 'pending'],
          type: 'string',
        },
        timeAwarded: { type: 'number' },
        frontendBidStatus: {
          enum: ['active', 'complete', 'in_progress'],
          type: 'string',
        },
        shortlisted: { type: 'boolean' },
        score: { type: 'number' },
        distance: { type: 'number' },
        negotiatedOffer: { $ref: 'defs.json#/definitions/NegotiatedOfferApi' },
        hidden: { type: 'boolean' },
        hiddenReason: {
          enum: [
            'advertising',
            'contacts',
            'fake',
            'harassment',
            'not_interesting',
          ],
          type: 'string',
        },
        timeAccepted: { type: 'number' },
        paidAmount: { type: 'number' },
        hourlyRate: { type: 'number' },
        isLocationTracked: { type: 'boolean' },
        awardExpireTime: {
          description: '36 hours after bid is awarded',
          type: 'number',
        },
        extraForUpdate: { $ref: 'defs.json#/definitions/BidAwardExtraFields' },
        sealed: { type: 'boolean' },
        completeStatusChangedTime: { type: 'number' },
        awardStatusChangedTime: { type: 'number' },
        rejectReasonCode: {
          enum: [
            'both_budget',
            'employer_nomilestone',
            'employer_spam',
            'employer_unclear',
            'freelancer_enoughwork',
            'freelancer_noskills',
            'freelancer_notime',
            'other',
          ],
          type: 'string',
        },
        otherFeedback: { type: 'string' },
        rating: { $ref: 'defs.json#/definitions/BidRating' },
        quotations: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/QuotationRevision' },
        },
        pitchId: { type: 'string' },
        salesTax: { $ref: 'defs.json#/definitions/CreatedBidSalesTax' },
        profileId: { type: 'number' },
        isAutomaticBillingEnabled: { type: 'boolean' },
      },
      required: [
        'amount',
        'bidderId',
        'id',
        'period',
        'projectId',
        'submitDate',
      ],
    },
    Fee: {
      type: 'object',
      properties: {
        amount: { type: 'number' },
        is_taxed: { type: 'boolean' },
        currency: { $ref: 'defs.json#/definitions/Currency' },
        rate: { type: 'number' },
        rate_with_tax: { type: 'number' },
      },
      required: ['amount', 'is_taxed'],
    },
    NegotiatedOfferApi: {
      type: 'object',
      properties: {
        project_id: { type: 'number' },
        freelancer_id: { type: 'number' },
        status: {
          enum: [
            'employer_accepted',
            'employer_pending',
            'employer_rejected',
            'employer_requested',
            'freelancer_pending',
            'freelancer_rejected',
          ],
          type: 'string',
        },
        requested_milestones: { enum: ['all', 'none', 'one'], type: 'string' },
        time_updated: { type: 'number' },
      },
    },
    BidAwardExtraFields: {
      type: 'object',
      properties: {
        workLimit: { type: 'number' },
        billingCycle: { enum: ['monthly', 'weekly'], type: 'string' },
        skipHourlyContract: { type: 'boolean' },
      },
    },
    BidRating: {
      description:
        'Bids can be rated by employers, and this information is represented\nin this interface.\n\nBid ratings will primarily be fetched and updated on the Proposals tab\nof the Project View page',
      type: 'object',
      properties: {
        authorId: { type: 'number' },
        bidId: { type: 'number' },
        comment: { type: 'string' },
        id: { type: 'number' },
        rating: { type: 'number' },
        timeCreated: { type: 'number' },
        timeUpdated: { type: 'number' },
      },
      required: ['authorId', 'bidId', 'id', 'rating'],
    },
    QuotationRevision: {
      description: 'A quotation revision',
      type: 'object',
      properties: {
        id: { type: 'number' },
        quotationId: { type: 'number' },
        quotationUid: { type: 'string' },
        versionUid: { type: 'string' },
        contextType: { $ref: 'defs.json#/definitions/QuotationContextTypeApi' },
        contextId: { type: 'number' },
        creatorId: { type: 'number' },
        createDate: { type: 'number' },
        isRequestedByRecipient: { type: 'boolean' },
        defaultCurrencyId: { type: 'number' },
        approveDate: { type: 'number' },
        paidAmount: {
          description: 'Quotation amount of a specific currency',
          type: 'object',
          additionalProperties: false,
          patternProperties: { '^[0-9]+$': { type: 'number' } },
        },
        fundedAmount: {
          description: 'Quotation amount of a specific currency',
          type: 'object',
          additionalProperties: false,
          patternProperties: { '^[0-9]+$': { type: 'number' } },
        },
        quotePrice: {
          description: 'Quotation amount of a specific currency',
          type: 'object',
          additionalProperties: false,
          patternProperties: { '^[0-9]+$': { type: 'number' } },
        },
        title: { type: 'string' },
        description: { type: 'string' },
        richDescription: { type: 'string' },
        versionInvalidDate: { type: 'number' },
        parentVersionId: { type: 'number' },
        status: { $ref: 'defs.json#/definitions/QuotationStatusApi' },
        estimatedStartDate: { type: 'number' },
        estimatedEndDate: { type: 'number' },
        upfrontFundRequestAmount: { type: 'number' },
        rejectDate: { type: 'number' },
        cancelDate: { type: 'number' },
        expiryDate: { type: 'number' },
        durationInDays: { type: 'number' },
        recipientId: { type: 'number' },
        parentTemplateVersionId: { type: 'number' },
        parentTemplateContextId: {
          description:
            'This contains the service ID of service-related quotations.',
          type: 'number',
        },
        logoId: { type: 'number' },
      },
      required: [
        'contextId',
        'contextType',
        'createDate',
        'creatorId',
        'defaultCurrencyId',
        'fundedAmount',
        'id',
        'isRequestedByRecipient',
        'paidAmount',
        'quotationId',
        'quotationUid',
        'quotePrice',
        'status',
        'versionUid',
      ],
    },
    QuotationContextTypeApi: {
      enum: ['bid', 'external', 'project', 'template', 'thread', 'user'],
      type: 'string',
    },
    QuotationStatusApi: {
      enum: [
        'approved',
        'cancelled',
        'draft',
        'expired',
        'invalid',
        'pending',
        'rejected',
      ],
      type: 'string',
    },
    CreatedBidSalesTax: {
      type: 'object',
      properties: {
        taxType: { type: 'string' },
        taxNumber: { type: 'string' },
        taxRate: { type: 'number' },
      },
      required: ['taxNumber', 'taxRate', 'taxType'],
    },
    ProjectFileApi: {
      type: 'object',
      properties: {
        id: { type: 'number' },
        from_user_id: { type: 'number' },
        to_user_id: { type: 'number' },
        project_id: { type: 'number' },
        file_name: { type: 'string' },
        file_size: { type: 'number' },
        time_submitted: { type: 'number' },
        url: { type: 'string' },
        content_type: { type: 'string' },
      },
    },
    JobApi: {
      type: 'object',
      properties: {
        id: { type: 'number' },
        name: { type: 'string' },
        category: { $ref: 'defs.json#/definitions/CategoryApi' },
        active_project_count: { type: 'number' },
        seo_url: { type: 'string' },
        seo_info: { $ref: 'defs.json#/definitions/JobSeoInfoApi' },
        local: { type: 'boolean' },
        questions: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/SkillQuestionApi' },
        },
      },
      required: ['id'],
    },
    CategoryApi: {
      type: 'object',
      properties: { id: { type: 'number' }, name: { type: 'string' } },
      required: ['id'],
    },
    JobSeoInfoApi: {
      type: 'object',
      properties: {
        seo_text: { type: 'string' },
        seo_text_alt: { type: 'string' },
        seo_worker: { type: 'string' },
        seo_worker_alt: { type: 'string' },
        seo_worker_plural: { type: 'string' },
        seo_worker_plural_alt: { type: 'string' },
        phrase_worker: { type: 'string' },
        context_phrase_worker: { type: 'string' },
        plural_phrase_worker: { type: 'string' },
        country_person: { type: 'string' },
        country_demonym: { type: 'string' },
      },
    },
    SkillQuestionApi: {
      type: 'object',
      properties: {
        id: { type: 'number' },
        internal_name: { type: 'string' },
        display_name: { type: 'string' },
        skill_id: { type: 'number' },
        context_type: { type: 'string', enum: ['delivery_item'] },
      },
      required: [
        'context_type',
        'display_name',
        'id',
        'internal_name',
        'skill_id',
      ],
    },
    HireMeQuotation: {
      type: 'object',
      properties: {
        quotationId: { type: 'number' },
        quotationVersionId: { type: 'number' },
      },
      required: ['quotationId', 'quotationVersionId'],
    },
    SavedChatAttributes: {
      type: 'object',
      properties: {
        minimised: { type: 'boolean' },
        focus: { $ref: 'defs.json#/definitions/FocusState' },
        width: { type: 'number' },
        height: { type: 'number' },
        threadIdentifier: { $ref: 'defs.json#/definitions/ThreadIdentifier' },
        dimensions: { $ref: 'defs.json#/definitions/ChatBoxDimensions' },
        origin: { type: 'string' },
        timeCreated: { type: 'number' },
      },
      required: ['focus', 'height', 'minimised', 'threadIdentifier', 'width'],
    },
    FocusState: { enum: [0, 1, 2], type: 'number' },
    ThreadIdentifier: {
      type: 'object',
      properties: {
        id: { type: 'number' },
        threadType: { $ref: 'defs.json#/definitions/ThreadType' },
        context: {
          anyOf: [
            {
              type: 'object',
              properties: {
                type: { $ref: 'defs.json#/definitions/ThreadContextType' },
                id: { type: 'number' },
              },
              required: ['id', 'type'],
            },
            {
              type: 'object',
              properties: { type: { type: 'string', enum: ['none'] } },
              required: ['type'],
            },
          ],
        },
        members: { type: 'array', items: { type: 'number' } },
        isFake: { type: 'boolean' },
      },
      required: ['context', 'id', 'isFake', 'members', 'threadType'],
    },
    ThreadType: {
      enum: [
        'admin_preferred_chat',
        'ai_chat',
        'group',
        'pcb',
        'primary',
        'private_chat',
        'promotional_chat',
        'support_chat',
        'team',
        'team_official',
      ],
      type: 'string',
    },
    ThreadContextType: {
      enum: [
        'chat_request',
        'contest',
        'post',
        'prehire',
        'project',
        'support_chat',
        'support_session',
        'task',
        'team',
      ],
      type: 'string',
    },
    ChatBoxDimensions: {
      type: 'object',
      properties: { width: { type: 'number' }, height: { type: 'number' } },
      required: ['height', 'width'],
    },
    DraftMessage: {
      type: 'object',
      properties: { text: { type: 'string' }, lastUpdated: { type: 'number' } },
      required: ['lastUpdated', 'text'],
    },
    TaskListState: {
      type: 'object',
      properties: {
        currentTaskClean: { type: 'boolean' },
        draftTask: {
          anyOf: [
            { $ref: 'defs.json#/definitions/Task' },
            { $ref: 'defs.json#/definitions/Partial<Task>' },
          ],
        },
      },
    },
    Task: {
      description:
        "A task on a project/group's task list.\npreviousTaskId and nextTaskId are only used for task moving\nTODO: T267570 previousTaskId is also used for parent_task_id",
      type: 'object',
      properties: {
        id: { type: 'number' },
        ownerId: { type: 'number' },
        createTime: { type: 'number' },
        source: { $ref: 'defs.json#/definitions/TaskSourceApi' },
        status: { $ref: 'defs.json#/definitions/TaskStatusApi' },
        assigneeId: { type: 'number' },
        dueDate: { type: 'number' },
        title: { type: 'string' },
        description: { type: 'string' },
        taskSectionId: { type: 'number' },
        position: { type: 'number' },
        previousTaskId: { type: 'number' },
        nextTaskId: { type: 'number' },
        startDate: { type: 'number' },
        taskGroupId: { type: 'number' },
        attachments: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/Attachment' },
        },
        attachmentFiles: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/AttachmentFile' },
        },
      },
      required: [
        'createTime',
        'dueDate',
        'id',
        'ownerId',
        'position',
        'source',
        'status',
      ],
    },
    TaskSourceApi: {
      type: 'object',
      properties: {
        id: { type: 'number' },
        type: { $ref: 'defs.json#/definitions/TaskSourceTypeApi' },
      },
      required: ['id', 'type'],
    },
    TaskSourceTypeApi: {
      enum: ['project', 'task_group_section'],
      type: 'string',
    },
    TaskStatusApi: {
      enum: ['closed_deleted', 'closed_resolved', 'in_progress'],
      type: 'string',
    },
    AttachmentFile: {
      description: 'It represents an attached file of Post',
      type: 'object',
      properties: {
        id: {
          description:
            'The return object by the back-end has a file_id field\nthat is unique for each files, we decided to use it as\na primary id of the front-end object in order to batch\nthe request.',
          type: 'string',
        },
        userId: { type: 'number' },
        fileName: { type: 'string' },
        fileSize: { type: 'number' },
        contentType: { type: 'string' },
        s3Bucket: { type: 'string' },
        s3Obj: { type: 'string' },
        isTemp: { type: 'boolean' },
        created: { type: 'number' },
        updated: { type: 'number' },
      },
      required: ['created', 'id', 'isTemp', 'userId'],
    },
    'Partial<Task>': {
      type: 'object',
      properties: {
        id: { type: 'number' },
        ownerId: { type: 'number' },
        createTime: { type: 'number' },
        source: { $ref: 'defs.json#/definitions/TaskSourceApi' },
        status: {
          enum: ['closed_deleted', 'closed_resolved', 'in_progress'],
          type: 'string',
        },
        assigneeId: { type: 'number' },
        dueDate: { type: 'number' },
        title: { type: 'string' },
        description: { type: 'string' },
        taskSectionId: { type: 'number' },
        position: { type: 'number' },
        previousTaskId: { type: 'number' },
        nextTaskId: { type: 'number' },
        startDate: { type: 'number' },
        taskGroupId: { type: 'number' },
        attachments: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/Attachment' },
        },
        attachmentFiles: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/AttachmentFile' },
        },
      },
    },
    LastSeenPjpAssistant: {
      type: 'object',
      properties: { time: { type: 'number' } },
      required: ['time'],
    },
    HireMeDraft: {
      type: 'object',
      properties: {
        budget: { type: 'number' },
        currencyId: { type: 'number' },
        freelancerId: { type: 'number' },
        hireFor: { type: 'string' },
        hoursPerWeek: { type: 'number' },
        message: { type: 'string' },
        confirmed: { type: 'boolean' },
      },
      required: [
        'budget',
        'currencyId',
        'freelancerId',
        'hireFor',
        'hoursPerWeek',
        'message',
      ],
    },
    BasePostJobPageFormState: {
      type: 'object',
      properties: {
        id: { type: 'number' },
        userId: { type: 'number' },
        submitDate: { type: ['null', 'number'] },
        title: { type: 'string' },
        description: { type: 'string' },
        skills: {
          anyOf: [
            { type: 'array', items: { $ref: 'defs.json#/definitions/Skill' } },
            { type: 'null' },
          ],
        },
        files: {
          anyOf: [
            {
              type: 'array',
              items: { $ref: 'defs.json#/definitions/TemporaryFile' },
            },
            { type: 'null' },
          ],
        },
        projectAdvancedUpgrades: {
          anyOf: [
            { $ref: 'defs.json#/definitions/ProjectAdvancedUpgrades' },
            { type: 'null' },
          ],
        },
        contestUpgrades: {
          anyOf: [
            { $ref: 'defs.json#/definitions/ContestUpgrade' },
            { type: 'null' },
          ],
        },
      },
      required: ['description', 'id', 'submitDate', 'title', 'userId'],
    },
    TemporaryFile: {
      type: 'object',
      properties: {
        id: { type: 'number' },
        name: { type: 'string' },
        size: { type: 'number' },
      },
      required: ['id', 'name', 'size'],
    },
    ProjectAdvancedUpgrades: {
      type: 'object',
      properties: {
        featured: { type: 'boolean' },
        nda: { type: 'boolean' },
        private: { type: 'boolean' },
        recruiter: { type: 'boolean' },
        urgent: { type: 'boolean' },
        ipContract: { type: 'boolean' },
        sealed: { type: 'boolean' },
      },
    },
    ContestUpgrade: {
      type: 'object',
      properties: {
        guaranteed: { type: 'boolean' },
        featured: { type: 'boolean' },
        topContest: { type: 'boolean' },
        highlight: { type: 'boolean' },
        sealed: { type: 'boolean' },
        nda: { type: 'boolean' },
        private: { type: 'boolean' },
        urgent: { type: 'boolean' },
        customJudging: { type: 'boolean' },
        extraForUpdate: {
          type: 'object',
          properties: {
            extended: {
              enum: [
                'five_days',
                'fourteen_days',
                'seven_days',
                'three_days',
                'twenty_one_days',
              ],
              type: 'string',
            },
          },
        },
      },
      required: [
        'customJudging',
        'featured',
        'guaranteed',
        'highlight',
        'nda',
        'private',
        'sealed',
        'topContest',
        'urgent',
      ],
    },
    ProjectBudgetOption: {
      description:
        'A budget range for a project.\nPulled from the Projects API.',
      type: 'object',
      properties: {
        id: { type: 'string' },
        minimum: { type: 'number' },
        maximum: { type: 'number' },
        name: { type: 'string' },
        projectType: { $ref: 'defs.json#/definitions/ProjectTypeApi' },
        currencyId: { type: 'number' },
        language: { type: 'string' },
      },
      required: ['currencyId', 'id', 'minimum', 'name', 'projectType'],
    },
    DelioitteGigWorkerLevel: {
      type: 'object',
      properties: {
        analyst: { type: 'boolean' },
        consultant: { type: 'boolean' },
        senior_consultant: { type: 'boolean' },
        manager: { type: 'boolean' },
        senior_manager: { type: 'boolean' },
        ppmd: { type: 'boolean' },
      },
      required: [
        'analyst',
        'consultant',
        'manager',
        'ppmd',
        'senior_consultant',
        'senior_manager',
      ],
    },
    DeloitteAudienceType: { enum: ['deloitte', 'external'], type: 'string' },
    ManageRecentTable: {
      description:
        'Interface used for remembering recently viewed tables in /manage',
      type: 'object',
      properties: {
        employerTable: { type: 'string' },
        collaboratorTable: { type: 'string' },
        freelancerTable: { type: 'string' },
        insourceTable: { type: 'string' },
        manageTable: { type: 'string' },
      },
      required: [
        'collaboratorTable',
        'employerTable',
        'freelancerTable',
        'insourceTable',
      ],
    },
    ABTestVariationCache: {
      type: 'object',
      additionalProperties: {
        $ref: 'defs.json#/definitions/ABTestVariation<keyofSessionExperiments|keyofUserExperiments|keyofProjectExperiments|keyofRateLimitingExperiments>',
      },
    },
    'ABTestVariation<keyofSessionExperiments|keyofUserExperiments|keyofProjectExperiments|keyofRateLimitingExperiments>':
      {
        description: 'Interface used for AB Test caching',
        type: 'object',
        properties: {
          variation: {
            enum: [
              '50',
              'CONTROL',
              'Control',
              'Control1',
              'Control2',
              'DO NOT DECLARE THIS A/B TEST OR IT MAY BREAK THE WEBAPP BUILD',
              'TEST',
              'Test',
              'Test1',
              'annual',
              'auto_chat',
              'blockCdaAlerts',
              'both',
              'control',
              'control_1',
              'control_2',
              'control_3',
              'hide notifications side bar',
              'keep notifications sidebar',
              'new_bid_card',
              'new_predictor',
              'no-screenshare',
              'normal login',
              'normal-flow',
              'old_bid_card',
              'old_predictor',
              'send email',
              'show-banner',
              'show-modal',
              'show-paytm',
              'test',
              'triggerCdaAlerts',
              'variation',
              'with-non-compete',
              'with-screenshare',
            ],
            type: 'string',
          },
          expiry: { type: 'number' },
        },
        required: ['expiry', 'variation'],
      },
    'Partial<{project:ItemsViewedMap;}>': {
      type: 'object',
      properties: {
        project: {
          type: 'object',
          additionalProperties: false,
          patternProperties: { '^[0-9]+$': { type: 'number' } },
        },
      },
    },
    SearchFilters: {
      type: 'object',
      properties: {
        users: { $ref: 'defs.json#/definitions/UserFilterType' },
        projects: { $ref: 'defs.json#/definitions/ProjectFilterType' },
        challenges: { $ref: 'defs.json#/definitions/ChallengesFilterType' },
        contests: { $ref: 'defs.json#/definitions/ContestFilterType' },
        shared: { $ref: 'defs.json#/definitions/SharedFilterType' },
        freightlancerDrivers: {
          $ref: 'defs.json#/definitions/FreightlancerDriverFilterType',
        },
        freightlancerLoads: {
          $ref: 'defs.json#/definitions/FreightlancerLoadFilterType',
        },
        deloitteUsers: {
          $ref: 'defs.json#/definitions/DeloitteUserFilterType',
        },
        userAvailabilities: {
          $ref: 'defs.json#/definitions/UserAvailabilityFilterType',
        },
      },
    },
    UserFilterType: {
      type: 'object',
      properties: {
        userCountries: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/FilterArrayItem<string>' },
        },
        exams: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/FilterArrayItem<number>' },
        },
        hourlyRateMax: { type: 'number' },
        hourlyRateMin: { type: 'number' },
        online: { type: 'boolean' },
        rating: { type: 'number' },
        reviewCountMax: { type: 'number' },
        reviewCountMin: { type: 'number' },
        userSkills: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/FilterArrayItem<number>' },
        },
        userSort: {
          enum: [
            'highestHourlyRate',
            'highestRatings',
            'leastReviews',
            'lowestHourlyRate',
            'lowestRatings',
            'mostRelevant',
            'mostReviews',
          ],
          type: 'string',
        },
      },
      required: ['exams', 'userCountries', 'userSkills'],
    },
    'FilterArrayItem<string>': {
      type: 'object',
      properties: { selected: { type: 'boolean' }, value: { type: 'string' } },
      required: ['selected', 'value'],
    },
    'FilterArrayItem<number>': {
      type: 'object',
      properties: { selected: { type: 'boolean' }, value: { type: 'number' } },
      required: ['selected', 'value'],
    },
    ProjectFilterType: {
      type: 'object',
      properties: {
        clientCountries: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/FilterArrayItem<string>' },
        },
        projectFixedPriceMax: { type: 'number' },
        projectFixedPriceMin: { type: 'number' },
        projectHourlyRateMax: { type: 'number' },
        projectHourlyRateMin: { type: 'number' },
        projectLanguages: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/FilterArrayItem<string>' },
        },
        projectLocation: { $ref: 'defs.json#/definitions/LocationFilter' },
        projectSkills: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/FilterArrayItem<number>' },
        },
        projectSort: {
          enum: [
            'fewestBids',
            'highestPrice',
            'latest',
            'lowestPrice',
            'mostBids',
            'oldest',
          ],
          type: 'string',
        },
        types: {
          type: 'array',
          items: {
            $ref: 'defs.json#/definitions/FilterArrayItem<"fixed"|"hourly">',
          },
        },
        projectUpgrades: {
          type: 'array',
          items: {
            $ref: 'defs.json#/definitions/FilterArrayItem<"featured"|"sealed"|"NDA"|"urgent"|"fulltime"|"assisted"|"ip_agreement">',
          },
        },
      },
      required: [
        'clientCountries',
        'projectLanguages',
        'projectSkills',
        'projectUpgrades',
        'types',
      ],
    },
    LocationFilter: {
      type: 'object',
      properties: {
        country: { type: 'string' },
        fullAddress: { type: 'string' },
        latitude: { type: 'number' },
        longitude: { type: 'number' },
      },
      required: ['fullAddress', 'latitude', 'longitude'],
    },
    'FilterArrayItem<"fixed"|"hourly">': {
      type: 'object',
      properties: {
        selected: { type: 'boolean' },
        value: { $ref: 'defs.json#/definitions/FilterArrayItem.T' },
      },
      required: ['selected', 'value'],
    },
    'FilterArrayItem.T': { enum: ['fixed', 'hourly'], type: 'string' },
    'FilterArrayItem<"featured"|"sealed"|"NDA"|"urgent"|"fulltime"|"assisted"|"ip_agreement">':
      {
        type: 'object',
        properties: {
          selected: { type: 'boolean' },
          value: { $ref: 'defs.json#/definitions/FilterArrayItem.T_1' },
        },
        required: ['selected', 'value'],
      },
    'FilterArrayItem.T_1': {
      enum: [
        'NDA',
        'assisted',
        'featured',
        'fulltime',
        'ip_agreement',
        'sealed',
        'urgent',
      ],
      type: 'string',
    },
    ChallengesFilterType: {
      type: 'object',
      properties: { challengeSort: { enum: ['default'], type: 'string' } },
    },
    ContestFilterType: {
      type: 'object',
      properties: {
        prizeMax: { type: 'number' },
        prizeMin: { type: 'number' },
        contestSkills: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/FilterArrayItem<number>' },
        },
        contestSort: {
          enum: [
            'fewestEntries',
            'highestPrize',
            'latest',
            'lowestPrize',
            'mostEntries',
            'oldest',
          ],
          type: 'string',
        },
        contestUpgrades: {
          type: 'array',
          items: {
            $ref: 'defs.json#/definitions/FilterArrayItem<"highlight"|"featured"|"sealed"|"guaranteed"|"topContest"|"NDA"|"urgent">',
          },
        },
      },
      required: ['contestSkills', 'contestUpgrades'],
    },
    'FilterArrayItem<"highlight"|"featured"|"sealed"|"guaranteed"|"topContest"|"NDA"|"urgent">':
      {
        type: 'object',
        properties: {
          selected: { type: 'boolean' },
          value: { $ref: 'defs.json#/definitions/FilterArrayItem.T_2' },
        },
        required: ['selected', 'value'],
      },
    'FilterArrayItem.T_2': {
      enum: [
        'NDA',
        'featured',
        'guaranteed',
        'highlight',
        'sealed',
        'topContest',
        'urgent',
      ],
      type: 'string',
    },
    SharedFilterType: {
      type: 'object',
      properties: {
        page: { type: 'number' },
        pools: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/FilterArrayItem<number>' },
        },
        q: { type: 'string' },
      },
      required: ['pools', 'q'],
    },
    FreightlancerDriverFilterType: {
      type: 'object',
      properties: {
        loadType: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/FilterArrayItem<string>' },
        },
        driverLocation: { $ref: 'defs.json#/definitions/LocationFilter' },
        rating: { type: 'number' },
        truckType: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/FilterArrayItem<string>' },
        },
      },
      required: ['loadType', 'truckType'],
    },
    FreightlancerLoadFilterType: {
      type: 'object',
      properties: {
        loadAdministrativeAreasEnd: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/FilterArrayItem<string>' },
        },
        loadAdministrativeAreasStart: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/FilterArrayItem<string>' },
        },
        loadTypes: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/FilterArrayItem<number>' },
        },
        loadOperatingAreasEnd: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/FilterArrayItem<number>' },
        },
        loadOperatingAreasStart: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/FilterArrayItem<number>' },
        },
        maxWidth: { type: 'number' },
        maxLength: { type: 'number' },
        minWeight: { type: 'number' },
        maxWeight: { type: 'number' },
        minWorkStartDate: { type: 'number' },
        maxWorkStartDate: { type: 'number' },
        loadSort: { enum: ['Latest', 'Oldest'], type: 'string' },
        userHasBid: { type: 'boolean' },
        loadBookmarks: { type: 'boolean' },
        mapTopRightLatitude: { type: 'number' },
        mapTopRightLongitude: { type: 'number' },
        mapBottomLeftLatitude: { type: 'number' },
        mapBottomLeftLongitude: { type: 'number' },
        mapCenterLatitude: { type: 'number' },
        mapCenterLongitude: { type: 'number' },
        mapZoom: { type: 'number' },
      },
      required: [
        'loadAdministrativeAreasEnd',
        'loadAdministrativeAreasStart',
        'loadOperatingAreasEnd',
        'loadOperatingAreasStart',
        'loadTypes',
      ],
    },
    DeloitteUserFilterType: {
      type: 'object',
      properties: {
        certifications: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/FilterArrayItem<string>' },
        },
        countryAlignment: { type: 'array', items: { type: 'string' } },
        level: { type: 'array', items: { type: 'string' } },
        independenceRuleset: { type: 'array', items: { type: 'string' } },
        legalEntity: { type: 'array', items: { type: 'string' } },
        location: { $ref: 'defs.json#/definitions/LocationFilter' },
        memberFirmAlignment: { type: 'array', items: { type: 'string' } },
        offeringPortfolio: { type: 'array', items: { type: 'string' } },
        practice: { type: 'array', items: { type: 'string' } },
      },
      required: [
        'certifications',
        'countryAlignment',
        'independenceRuleset',
        'legalEntity',
        'level',
        'memberFirmAlignment',
        'offeringPortfolio',
        'practice',
      ],
    },
    UserAvailabilityFilterType: {
      type: 'object',
      properties: {
        preferredPickupAreas: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/FilterArrayItem<number>' },
        },
        preferredDropoffAreas: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/FilterArrayItem<number>' },
        },
        vehicleTypes: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/FilterArrayItem<number>' },
        },
      },
      required: [
        'preferredDropoffAreas',
        'preferredPickupAreas',
        'vehicleTypes',
      ],
    },
    UserParsedResume: {
      description:
        'Parsed resume results\nUsed on the freelancer onboarding flow after a user uploads their resume to Pelican',
      type: 'object',
      properties: {
        id: { type: 'string' },
        firstName: { type: 'string' },
        lastName: { type: 'string' },
        tagline: { type: 'string' },
        profileDescription: { type: 'string' },
        educations: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/ParsedEducation' },
        },
        experiences: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/ParsedExperience' },
        },
      },
      required: ['id'],
    },
    ParsedEducation: {
      description: 'Education fields available from parsed resume result',
      type: 'object',
      properties: {
        schoolName: { type: 'string' },
        degree: { type: 'string' },
        startDate: { $ref: 'defs.json#/definitions/DateLabel' },
        endDate: { $ref: 'defs.json#/definitions/DateLabel' },
        country: { type: 'string' },
      },
    },
    DateLabel: {
      type: 'object',
      properties: {
        year: { type: 'number' },
        month: { type: 'number' },
        day: { type: 'number' },
      },
      required: ['day', 'month', 'year'],
    },
    ParsedExperience: {
      description: 'Experience fields available from parsed resume result',
      type: 'object',
      properties: {
        title: { type: 'string' },
        company: { type: 'string' },
        description: { type: 'string' },
        startDate: { $ref: 'defs.json#/definitions/DateLabel' },
        endDate: { $ref: 'defs.json#/definitions/DateLabel' },
      },
    },
    LastUsedForms: {
      description: 'Interfaces used for Generic Form Save functionality.',
      type: 'object',
      additionalProperties: {
        $ref: 'defs.json#/definitions/LastUsedFormsSection',
      },
    },
    LastUsedFormsSection: { type: 'object', additionalProperties: {} },
    MockProfile: {
      type: 'object',
      properties: { id: { type: 'number' }, name: { type: 'string' } },
      required: ['id', 'name'],
    },
    ExternalQuotation: {
      type: 'object',
      properties: { email: { type: 'string' } },
      required: ['email'],
    },
    GroupsViewFilterSelection: {
      description: 'Interface for group homepage view state',
      enum: ['grid', 'list'],
      type: 'string',
    },
    WaitingScreenSteps: {
      type: 'object',
      properties: {
        projectId: { type: 'number' },
        formStep: { type: 'number' },
        vehicleStep: { type: 'number' },
      },
      required: ['formStep', 'projectId', 'vehicleStep'],
    },
    AiConsultantMessage: {
      type: 'object',
      properties: {
        content: { type: 'string' },
        role: { $ref: 'defs.json#/definitions/AiConsultantMessageRole' },
        type: {
          enum: ['chat', 'draft_project', 'error', 'limit', 'loading'],
          type: 'string',
        },
      },
      required: ['content', 'role'],
    },
    AiConsultantMessageRole: { enum: ['assistant', 'user'], type: 'string' },
    AiConsultantGeneratedProjectDraft: {
      type: 'object',
      properties: {
        title: { type: 'string' },
        description: { type: 'string' },
      },
      required: ['description', 'title'],
    },
    AiPlaygroundConversationSaveFile: {
      description:
        'Type used for storing a playground conversation into localstorage',
      type: 'object',
      properties: {
        id: { type: 'string' },
        name: { type: 'string' },
        conversation: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/AiPlaygroundMessage' },
        },
        config: {
          $ref: 'defs.json#/definitions/Partial<{readonlymodel:AiModelApi;readonlytemperature:number;readonlymaxTokens:number;readonlytopP:number;readonlyfrequencyPenalty:number;readonlypresencePenalty:number;readonlymoderators:string;readonlyreasoningEffort:AiRequestReasoningEffortOptions;readonlyvectorSearch:boolean;readonlyresponseFormat:"object"|"text"|"schema";readonlystructuredOutput:string;}>',
        },
        savedTools: {
          type: 'array',
          items: { $ref: 'defs.json#/definitions/AiPlaygroundSavedTool' },
        },
      },
      required: ['config', 'conversation', 'id', 'name', 'savedTools'],
    },
    AiPlaygroundMessage: {
      type: 'object',
      properties: {
        id: { type: 'string' },
        type: { $ref: 'defs.json#/definitions/Role' },
        messages: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              message: { type: 'string' },
              modelUsed: {
                enum: [
                  'azure_gpt_4_o_2024_11_20',
                  'azure_gpt_4_o_mini_2024_07_18',
                  'bedrock_claude_3_5_sonnet_20240620',
                  'bedrock_claude_3_7_sonnet_20250219',
                  'bid_writer_model',
                  'chat_gpt_3_5_turbo',
                  'chat_gpt_4_0125_preview',
                  'chat_gpt_4_1106_preview',
                  'chat_gpt_4_o_2024_05_13',
                  'chat_gpt_4_o_2024_11_20',
                  'chat_gpt_4_o_mini_2024_07_18',
                  'chat_gpt_4_turbo_2024_04_09',
                  'openai_gpt_4_5_preview_2025_02_27',
                  'openai_o1_2024_12_17',
                  'openai_o3_mini_2025_01_31',
                  'openai_text_embedding_3_small',
                ],
                type: 'string',
              },
            },
            required: ['message'],
          },
        },
        imageUrls: { type: 'array', items: { type: 'string' } },
        tool: { $ref: 'defs.json#/definitions/ToolInfo' },
        metadata: { $ref: 'defs.json#/definitions/AiModelResponseMetadata' },
      },
      required: ['id', 'messages', 'type'],
    },
    Role: {
      enum: [
        'assistant',
        'error',
        'info',
        'moderator',
        'request_error',
        'system',
        'tool_call',
        'user',
      ],
      type: 'string',
    },
    ToolInfo: {
      type: 'object',
      properties: {
        arguments: { $ref: 'defs.json#/definitions/Record<string,string>' },
        result: { type: 'string' },
        name: { type: 'string' },
      },
      required: ['arguments', 'name'],
    },
    'Record<string,string>': { type: 'object' },
    AiModelResponseMetadata: {
      type: 'object',
      properties: {
        cachedTokens: { type: 'number' },
        completionTokens: { type: 'number' },
        promptTokens: { type: 'number' },
        totalTokens: { type: 'number' },
      },
      required: [
        'cachedTokens',
        'completionTokens',
        'promptTokens',
        'totalTokens',
      ],
    },
    'Partial<{readonlymodel:AiModelApi;readonlytemperature:number;readonlymaxTokens:number;readonlytopP:number;readonlyfrequencyPenalty:number;readonlypresencePenalty:number;readonlymoderators:string;readonlyreasoningEffort:AiRequestReasoningEffortOptions;readonlyvectorSearch:boolean;readonlyresponseFormat:"object"|"text"|"schema";readonlystructuredOutput:string;}>':
      {
        type: 'object',
        properties: {
          model: {
            description:
              'Tracks the value and validation status of an individual form control.\n\nThis is one of the four fundamental building blocks of Angular forms, along with\n`FormGroup`, `FormArray` and `FormRecord`. It extends the `AbstractControl` class that\nimplements most of the base functionality for accessing the value, validation status,\nuser interactions and events.\n\n`FormControl` takes a single generic argument, which describes the type of its value. This\nargument always implicitly includes `null` because the control can be reset. To change this\nbehavior, set `nonNullable` or see the usage notes below.\n\nSee [usage examples below](#usage-notes).',
            enum: [
              'azure_gpt_4_o_2024_11_20',
              'azure_gpt_4_o_mini_2024_07_18',
              'bedrock_claude_3_5_sonnet_20240620',
              'bedrock_claude_3_7_sonnet_20250219',
              'bid_writer_model',
              'chat_gpt_3_5_turbo',
              'chat_gpt_4_0125_preview',
              'chat_gpt_4_1106_preview',
              'chat_gpt_4_o_2024_05_13',
              'chat_gpt_4_o_2024_11_20',
              'chat_gpt_4_o_mini_2024_07_18',
              'chat_gpt_4_turbo_2024_04_09',
              'openai_gpt_4_5_preview_2025_02_27',
              'openai_o1_2024_12_17',
              'openai_o3_mini_2025_01_31',
              'openai_text_embedding_3_small',
            ],
            type: 'string',
          },
          temperature: {
            description:
              'Tracks the value and validation status of an individual form control.\n\nThis is one of the four fundamental building blocks of Angular forms, along with\n`FormGroup`, `FormArray` and `FormRecord`. It extends the `AbstractControl` class that\nimplements most of the base functionality for accessing the value, validation status,\nuser interactions and events.\n\n`FormControl` takes a single generic argument, which describes the type of its value. This\nargument always implicitly includes `null` because the control can be reset. To change this\nbehavior, set `nonNullable` or see the usage notes below.\n\nSee [usage examples below](#usage-notes).',
            type: 'number',
          },
          maxTokens: {
            description:
              'Tracks the value and validation status of an individual form control.\n\nThis is one of the four fundamental building blocks of Angular forms, along with\n`FormGroup`, `FormArray` and `FormRecord`. It extends the `AbstractControl` class that\nimplements most of the base functionality for accessing the value, validation status,\nuser interactions and events.\n\n`FormControl` takes a single generic argument, which describes the type of its value. This\nargument always implicitly includes `null` because the control can be reset. To change this\nbehavior, set `nonNullable` or see the usage notes below.\n\nSee [usage examples below](#usage-notes).',
            type: 'number',
          },
          topP: {
            description:
              'Tracks the value and validation status of an individual form control.\n\nThis is one of the four fundamental building blocks of Angular forms, along with\n`FormGroup`, `FormArray` and `FormRecord`. It extends the `AbstractControl` class that\nimplements most of the base functionality for accessing the value, validation status,\nuser interactions and events.\n\n`FormControl` takes a single generic argument, which describes the type of its value. This\nargument always implicitly includes `null` because the control can be reset. To change this\nbehavior, set `nonNullable` or see the usage notes below.\n\nSee [usage examples below](#usage-notes).',
            type: 'number',
          },
          frequencyPenalty: {
            description:
              'Tracks the value and validation status of an individual form control.\n\nThis is one of the four fundamental building blocks of Angular forms, along with\n`FormGroup`, `FormArray` and `FormRecord`. It extends the `AbstractControl` class that\nimplements most of the base functionality for accessing the value, validation status,\nuser interactions and events.\n\n`FormControl` takes a single generic argument, which describes the type of its value. This\nargument always implicitly includes `null` because the control can be reset. To change this\nbehavior, set `nonNullable` or see the usage notes below.\n\nSee [usage examples below](#usage-notes).',
            type: 'number',
          },
          presencePenalty: {
            description:
              'Tracks the value and validation status of an individual form control.\n\nThis is one of the four fundamental building blocks of Angular forms, along with\n`FormGroup`, `FormArray` and `FormRecord`. It extends the `AbstractControl` class that\nimplements most of the base functionality for accessing the value, validation status,\nuser interactions and events.\n\n`FormControl` takes a single generic argument, which describes the type of its value. This\nargument always implicitly includes `null` because the control can be reset. To change this\nbehavior, set `nonNullable` or see the usage notes below.\n\nSee [usage examples below](#usage-notes).',
            type: 'number',
          },
          moderators: {
            description:
              'Tracks the value and validation status of an individual form control.\n\nThis is one of the four fundamental building blocks of Angular forms, along with\n`FormGroup`, `FormArray` and `FormRecord`. It extends the `AbstractControl` class that\nimplements most of the base functionality for accessing the value, validation status,\nuser interactions and events.\n\n`FormControl` takes a single generic argument, which describes the type of its value. This\nargument always implicitly includes `null` because the control can be reset. To change this\nbehavior, set `nonNullable` or see the usage notes below.\n\nSee [usage examples below](#usage-notes).',
            type: 'string',
          },
          reasoningEffort: {
            description:
              'Tracks the value and validation status of an individual form control.\n\nThis is one of the four fundamental building blocks of Angular forms, along with\n`FormGroup`, `FormArray` and `FormRecord`. It extends the `AbstractControl` class that\nimplements most of the base functionality for accessing the value, validation status,\nuser interactions and events.\n\n`FormControl` takes a single generic argument, which describes the type of its value. This\nargument always implicitly includes `null` because the control can be reset. To change this\nbehavior, set `nonNullable` or see the usage notes below.\n\nSee [usage examples below](#usage-notes).',
            enum: ['high', 'low', 'medium', 'not_applicable'],
            type: 'string',
          },
          vectorSearch: {
            description:
              'Tracks the value and validation status of an individual form control.\n\nThis is one of the four fundamental building blocks of Angular forms, along with\n`FormGroup`, `FormArray` and `FormRecord`. It extends the `AbstractControl` class that\nimplements most of the base functionality for accessing the value, validation status,\nuser interactions and events.\n\n`FormControl` takes a single generic argument, which describes the type of its value. This\nargument always implicitly includes `null` because the control can be reset. To change this\nbehavior, set `nonNullable` or see the usage notes below.\n\nSee [usage examples below](#usage-notes).',
            type: 'boolean',
          },
          responseFormat: {
            description:
              'Tracks the value and validation status of an individual form control.\n\nThis is one of the four fundamental building blocks of Angular forms, along with\n`FormGroup`, `FormArray` and `FormRecord`. It extends the `AbstractControl` class that\nimplements most of the base functionality for accessing the value, validation status,\nuser interactions and events.\n\n`FormControl` takes a single generic argument, which describes the type of its value. This\nargument always implicitly includes `null` because the control can be reset. To change this\nbehavior, set `nonNullable` or see the usage notes below.\n\nSee [usage examples below](#usage-notes).',
            enum: ['object', 'schema', 'text'],
            type: 'string',
          },
          structuredOutput: {
            description:
              'Tracks the value and validation status of an individual form control.\n\nThis is one of the four fundamental building blocks of Angular forms, along with\n`FormGroup`, `FormArray` and `FormRecord`. It extends the `AbstractControl` class that\nimplements most of the base functionality for accessing the value, validation status,\nuser interactions and events.\n\n`FormControl` takes a single generic argument, which describes the type of its value. This\nargument always implicitly includes `null` because the control can be reset. To change this\nbehavior, set `nonNullable` or see the usage notes below.\n\nSee [usage examples below](#usage-notes).',
            type: 'string',
          },
        },
      },
    AiPlaygroundSavedTool: {
      type: 'object',
      properties: {
        toggled: { type: 'boolean' },
        name: {
          description:
            'Tracks the value and validation status of an individual form control.\n\nThis is one of the four fundamental building blocks of Angular forms, along with\n`FormGroup`, `FormArray` and `FormRecord`. It extends the `AbstractControl` class that\nimplements most of the base functionality for accessing the value, validation status,\nuser interactions and events.\n\n`FormControl` takes a single generic argument, which describes the type of its value. This\nargument always implicitly includes `null` because the control can be reset. To change this\nbehavior, set `nonNullable` or see the usage notes below.\n\nSee [usage examples below](#usage-notes).',
          type: 'string',
        },
        description: {
          description:
            'Tracks the value and validation status of an individual form control.\n\nThis is one of the four fundamental building blocks of Angular forms, along with\n`FormGroup`, `FormArray` and `FormRecord`. It extends the `AbstractControl` class that\nimplements most of the base functionality for accessing the value, validation status,\nuser interactions and events.\n\n`FormControl` takes a single generic argument, which describes the type of its value. This\nargument always implicitly includes `null` because the control can be reset. To change this\nbehavior, set `nonNullable` or see the usage notes below.\n\nSee [usage examples below](#usage-notes).',
          type: 'string',
        },
        parameters: {
          description:
            'Tracks the value and validity state of an array of `FormControl`,\n`FormGroup` or `FormArray` instances.\n\nA `FormArray` aggregates the values of each child `FormControl` into an array.\nIt calculates its status by reducing the status values of its children. For example, if one of\nthe controls in a `FormArray` is invalid, the entire array becomes invalid.\n\n`FormArray` accepts one generic argument, which is the type of the controls inside.\nIf you need a heterogenous array, use{@linkUntypedFormArray}.\n\n`FormArray` is one of the four fundamental building blocks used to define forms in Angular,\nalong with `FormControl`, `FormGroup`, and `FormRecord`.',
          type: 'array',
          items: {
            $ref: 'defs.json#/definitions/Partial<{readonlyname:string;readonlydescription:string;readonlytype:ParameterType;readonlyrequired:boolean;readonlyenumValue:string;}>',
          },
        },
      },
      required: ['toggled'],
    },
    'Partial<{readonlyname:string;readonlydescription:string;readonlytype:ParameterType;readonlyrequired:boolean;readonlyenumValue:string;}>':
      {
        type: 'object',
        properties: {
          name: {
            description:
              'Tracks the value and validation status of an individual form control.\n\nThis is one of the four fundamental building blocks of Angular forms, along with\n`FormGroup`, `FormArray` and `FormRecord`. It extends the `AbstractControl` class that\nimplements most of the base functionality for accessing the value, validation status,\nuser interactions and events.\n\n`FormControl` takes a single generic argument, which describes the type of its value. This\nargument always implicitly includes `null` because the control can be reset. To change this\nbehavior, set `nonNullable` or see the usage notes below.\n\nSee [usage examples below](#usage-notes).',
            type: 'string',
          },
          description: {
            description:
              'Tracks the value and validation status of an individual form control.\n\nThis is one of the four fundamental building blocks of Angular forms, along with\n`FormGroup`, `FormArray` and `FormRecord`. It extends the `AbstractControl` class that\nimplements most of the base functionality for accessing the value, validation status,\nuser interactions and events.\n\n`FormControl` takes a single generic argument, which describes the type of its value. This\nargument always implicitly includes `null` because the control can be reset. To change this\nbehavior, set `nonNullable` or see the usage notes below.\n\nSee [usage examples below](#usage-notes).',
            type: 'string',
          },
          type: {
            description:
              'Tracks the value and validation status of an individual form control.\n\nThis is one of the four fundamental building blocks of Angular forms, along with\n`FormGroup`, `FormArray` and `FormRecord`. It extends the `AbstractControl` class that\nimplements most of the base functionality for accessing the value, validation status,\nuser interactions and events.\n\n`FormControl` takes a single generic argument, which describes the type of its value. This\nargument always implicitly includes `null` because the control can be reset. To change this\nbehavior, set `nonNullable` or see the usage notes below.\n\nSee [usage examples below](#usage-notes).',
            enum: ['boolean', 'enum', 'number', 'string'],
            type: 'string',
          },
          required: {
            description:
              'Tracks the value and validation status of an individual form control.\n\nThis is one of the four fundamental building blocks of Angular forms, along with\n`FormGroup`, `FormArray` and `FormRecord`. It extends the `AbstractControl` class that\nimplements most of the base functionality for accessing the value, validation status,\nuser interactions and events.\n\n`FormControl` takes a single generic argument, which describes the type of its value. This\nargument always implicitly includes `null` because the control can be reset. To change this\nbehavior, set `nonNullable` or see the usage notes below.\n\nSee [usage examples below](#usage-notes).',
            type: 'boolean',
          },
          enumValue: {
            description:
              'Tracks the value and validation status of an individual form control.\n\nThis is one of the four fundamental building blocks of Angular forms, along with\n`FormGroup`, `FormArray` and `FormRecord`. It extends the `AbstractControl` class that\nimplements most of the base functionality for accessing the value, validation status,\nuser interactions and events.\n\n`FormControl` takes a single generic argument, which describes the type of its value. This\nargument always implicitly includes `null` because the control can be reset. To change this\nbehavior, set `nonNullable` or see the usage notes below.\n\nSee [usage examples below](#usage-notes).',
            type: 'string',
          },
        },
      },
    AppInstallPromptDismissals: {
      type: 'object',
      properties: {
        first_message: {
          type: 'object',
          properties: { lastDismissed: { type: 'number' } },
          required: ['lastDismissed'],
        },
        offline_message: {
          type: 'object',
          properties: { lastDismissed: { type: 'number' } },
          required: ['lastDismissed'],
        },
        email_link_shim: {
          type: 'object',
          properties: { lastDismissed: { type: 'number' } },
          required: ['lastDismissed'],
        },
      },
    },
  },
};
